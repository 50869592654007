import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface BlueButtonProps {
	children: string | string[];
	className: string;
	href?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
	'backgroundColor': '#3E4095',
	'color': 'white',
	'&:hover': {
		backgroundColor: theme.palette.grey[400],
		fontWeight: 800,
	},
}));

export const BlueButton = ({ children, className, href }: BlueButtonProps) => {
	const navigate = useNavigate();
	const handleLink = () => {
		href && navigate(href);
	};
	return (
		<StyledButton
			variant="contained"
			size="large"
			className={className}
			onClick={() => href && handleLink()}
		>
			{children}
		</StyledButton>
	);
};

import { Box } from '@mui/material';

interface DetailContainerProps {
	children: React.ReactNode;
}

export const DetailContainer = ({ children }: DetailContainerProps) => {
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottom: '1px solid grey',
				paddingBottom: '12.5px',
				columnGap: '12.5px',
				flexWrap: 'nowrap',
			}}
		>
			{children}
		</Box>
	);
};

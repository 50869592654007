import React, { ReactElement, useContext } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { StyledTableCell } from './batch-table-cell.component';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';

interface BatchTableProps {
	children: ReactElement | ReactElement[] | any[];
}

export const BatchTable = ({ children }: BatchTableProps) => {
	const { currency } = useContext(CambiappContext) as CambiappContextType;

	return (
		<TableContainer sx={{ overflow: 'unset' }} component={Paper}>
			{Array.isArray(children) &&
			children.length > 0 &&
			children[0].key !== null ? (
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Total ({currency})
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Denominación
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Versión
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Estado
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>{children}</TableBody>
				</Table>
			) : (
				<div>
					<TableHead>
						<TableRow>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Total ({currency})
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Denominación
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Versión
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}>
								Estado
							</StyledTableCell>
							<StyledTableCell sx={{ textAlign: 'center' }}></StyledTableCell>
						</TableRow>
					</TableHead>
					<Typography
						sx={{
							fontStyle: 'italic',
							padding: '20px',
						}}
					>
						No quedan elementos por mostrar
					</Typography>
				</div>
			)}
		</TableContainer>
	);
};

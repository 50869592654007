import { Typography } from '@mui/material';

const styles: { [key: string]: React.CSSProperties } = {
	container: {
		gridArea: '6 / 1 / 7 / 5',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		columnGap: '16px',
	},
	image: {
		height: '32px',
		width: '28px',
	},
};

export const TextualGuaranteeShield = () => {
	return (
		<div style={styles.container}>
			<img style={styles.image} src={'/shield_with_check.png'} alt="" />
			<Typography fontSize={16} color="#FCCB1A" fontWeight={600}>
				Operación Garantizada
			</Typography>
		</div>
	);
};

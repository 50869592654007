import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

interface ExpirationDateSelectionProps {
	action: (newExpiration: Moment) => void;
	expirationDate: Moment;
	closestDate: Moment;
	orderAmr: string;
	setShowMessage: (newState: boolean) => void;
}

export const ExpirationDateSelection = ({
	action,
	expirationDate,
	closestDate,
	setShowMessage,
}: ExpirationDateSelectionProps) => {
	const minimunExpiration = moment().add(15, 'minutes');
	const [newExpiration, setNewExpiration] = useState(expirationDate);

	const handleChange = (event: Moment | null) => {
		event && setNewExpiration(event);
	};

	useEffect(() => {
		if (
			moment(newExpiration).isAfter(minimunExpiration) &&
			moment(newExpiration).isBefore(closestDate.add(1, 'minute'))
		) {
			setShowMessage(false);
			action(newExpiration);
		} else setShowMessage(true);
	}, [minimunExpiration, newExpiration, closestDate, setShowMessage, action]);
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DateTimePicker
					label="Basic date time picker"
					format="DD/MM/YYYY HH:mm"
					ampm={false}
					ampmInClock={false}
					onChange={handleChange}
					minDateTime={minimunExpiration.subtract(1, 'minute')}
					maxDateTime={closestDate}
					value={newExpiration}
					sx={{
						'& .MuiInputBase-input': {
							color: 'black',
						},
						'& .MuiIconButton-root': {
							color: 'black',
						},
						'& .MuiOutlinedInput-notchedOutline': {
							border: '1px solid black',
							color: 'black',
						},
						'& label': {
							color: 'black',
						},
					}}
				/>
			</LocalizationProvider>
		</>
	);
};

import { ReactNode, createContext, useState } from 'react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { asyncHandler } from '../utils/async-handler';
import {
	CompletedPublication,
	Publication,
} from '../interfaces/publication.interface';
import moment from 'moment';
import { Currency } from '../interfaces/publication.interface';

export interface IAvailableAppointments {
	date: string;
	shift: string;
	isAvailable?: boolean;
}

interface ICambiappProvider {
	children: ReactNode;
}

interface INewOrder {
	type: 'COMPRA' | 'VENTA';
	price?: string;
	amr: string;
	availableAppointments: IAvailableAppointments[];
	isPartialAllowed: boolean;
	minimunPartialAmount: number;
	currency: Currency;
}

interface INewSellOrder extends INewOrder {
	firstBatch?: {
		amount: number;
		denomination: {
			name: string;
			value: number;
		};
		version: IVersion;
		status: string;
	};
	secondBatch?: {
		amount: number;
		denomination: {
			name: string;
			value: number;
		};
		version: IVersion;
		status: string;
	};
	minimunPesos?: {
		name: string;
		value: number;
	};
}

interface INewBuyOrder extends INewOrder {
	batch?: {
		amount: number;
		denomination: {
			name: string;
			value: number;
		};
		version: IVersion;
		status: string;
	};
	amountPesos?: {
		name: string;
		value: number;
	};
}

export interface IVersion {
	id: number;
	name: string;
	description: string;
	color: string;
}

export interface IDenominations {
	id: number;
	amount: number;
	name: string;
	versions: IVersion[];
}

export type CambiappContextType = {
	currency: Currency;
	setCurrency: (currency: Currency) => void;
	currencies: UseQueryResult<any[], Error>;
	isAdmin: UseQueryResult<boolean, Error>;
	isNewOrderPosible?: boolean;
	setNewOrderPosibility: (newState: boolean) => void;
	newSellOrder: INewSellOrder;
	setNewSellOrder: (newOrder: INewSellOrder) => void;
	newBuyOrder: INewBuyOrder;
	setNewBuyOrder: (newOrder: INewBuyOrder) => void;
	availableUsdVersions: IVersion[];
	setAvailableUsdVersions: (newOrder: IVersion[]) => void;
	selectedAvailableAppointments: IAvailableAppointments[];
	setSelectedAvailableAppointments: (
		availableAppointments: IAvailableAppointments[],
	) => void;
	orderAmr: string;
	setOrderAmr: (orderAmr: string) => void;
	expirationDate: string;
	setExpirationDate: (expirationDate: string) => void;
	sellPublications: UseQueryResult<Publication[], Error>;
	buyPublications: UseQueryResult<Publication[], Error>;
	confirmedSellPublications: UseQueryResult<Publication[], Error>;
	confirmedBuyPublications: UseQueryResult<Publication[], Error>;
	completedSellPublications: UseQueryResult<CompletedPublication[], Error>;
	completedBuyPublications: UseQueryResult<CompletedPublication[], Error>;
	expiredSellPublications: UseQueryResult<any[], Error>;
	expiredBuyPublications: UseQueryResult<any[], Error>;
	abortedSellPublications: UseQueryResult<any[], Error>;
	abortedBuyPublications: UseQueryResult<any[], Error>;
	statuses: UseQueryResult<string[], Error>;
	denominations: UseQueryResult<IDenominations[], Error>;
	arsDenominations: UseQueryResult<IDenominations[], Error>;
};

export const CambiappContext = createContext<CambiappContextType | null>(null);

export const CambiappProvider = ({ children }: ICambiappProvider) => {
	const [currency, setCurrency] = useState<Currency>(Currency.USD);

	const currencies = useQuery<any[], Error>(
		['currencies'],
		asyncHandler.retrieveCurrencies,
	);

	const sellPublications = useQuery<Publication[], Error>(
		['sellPublications', currency],
		() => asyncHandler.retrieveSellPublications(currency),
	);
	const buyPublications = useQuery<Publication[], Error>(
		['buyPublications', currency],
		() => asyncHandler.retrieveBuyPublications(currency),
	);
	const confirmedSellPublications = useQuery<Publication[], Error>(
		['confirmedSellPublications', currency],
		() => asyncHandler.retrieveConfirmedSellPublications(currency),
	);
	const confirmedBuyPublications = useQuery<Publication[], Error>(
		['confirmedBuyPublications', currency],
		() => asyncHandler.retrieveConfirmedBuyPublications(currency),
	);
	const completedSellPublications = useQuery<CompletedPublication[], Error>(
		['completedSellPublications', currency],
		() => asyncHandler.retrieveCompletedSellPublications(currency),
	);
	const completedBuyPublications = useQuery<CompletedPublication[], Error>(
		['completedBuyPublications', currency],
		() => asyncHandler.retrieveCompletedBuyPublications(currency),
	);
	const expiredSellPublications = useQuery<any[], Error>(
		['expiredSellPublications', currency],
		() => asyncHandler.retrieveExpiredSellPublications(currency),
	);
	const expiredBuyPublications = useQuery<any[], Error>(
		['expiredBuyPublications', currency],
		() => asyncHandler.retrieveExpiredBuyPublications(currency),
	);
	const abortedSellPublications = useQuery<any[], Error>(
		['abortedSellPublications', currency],
		() => asyncHandler.retrieveAbortedSellPublications(currency),
	);
	const abortedBuyPublications = useQuery<any[], Error>(
		['abortedBuyPublications', currency],
		() => asyncHandler.retrieveAbortedBuyPublications(currency),
	);
	const statuses = useQuery<string[], Error>(
		['statuses'],
		asyncHandler.retrieveStatuses,
	);
	const denominations = useQuery<IDenominations[], Error>(
		['denominations', currency],
		() => asyncHandler.retrieveDenominations(currency),
	);
	const arsDenominations = useQuery<IDenominations[], Error>(
		['arsDenominations'],
		asyncHandler.retrieveArsDenominations,
	);
	const [selectedAvailableAppointments, setSelectedAvailableAppointments] =
		useState<IAvailableAppointments[]>([]);
	const [orderAmr, setOrderAmr] = useState<string>('00:30');
	const [expirationDate, setExpirationDate] = useState<string>(
		moment().subtract(45, 'minutes').toISOString(),
	);
	const [newSellOrder, setNewSellOrder] = useState<INewSellOrder>({
		type: 'VENTA',
		amr: orderAmr,
		availableAppointments: selectedAvailableAppointments,
		isPartialAllowed: false,
		minimunPartialAmount: 0,
		currency: Currency.USD,
	});
	const [newBuyOrder, setNewBuyOrder] = useState<INewBuyOrder>({
		type: 'COMPRA',
		amr: orderAmr,
		availableAppointments: selectedAvailableAppointments,
		isPartialAllowed: false,
		minimunPartialAmount: 0,
		currency: Currency.USD,
	});
	const [availableUsdVersions, setAvailableUsdVersions] = useState<IVersion[]>(
		[],
	);
	const [isNewOrderPosible, setNewOrderPosibility] = useState<boolean>();
	const isAdmin = useQuery<boolean, Error>(
		['adminStatus'],
		asyncHandler.checkAdminStatus,
	);
	return (
		<CambiappContext.Provider
			value={{
				currency,
				setCurrency,
				currencies,
				isAdmin,
				isNewOrderPosible,
				setNewOrderPosibility,
				newSellOrder,
				setNewSellOrder,
				newBuyOrder,
				setNewBuyOrder,
				availableUsdVersions,
				setAvailableUsdVersions,
				selectedAvailableAppointments,
				setSelectedAvailableAppointments,
				orderAmr,
				setOrderAmr,
				expirationDate,
				setExpirationDate,
				sellPublications,
				confirmedSellPublications,
				completedSellPublications,
				abortedSellPublications,
				expiredSellPublications,
				buyPublications,
				confirmedBuyPublications,
				completedBuyPublications,
				abortedBuyPublications,
				expiredBuyPublications,
				statuses,
				denominations,
				arsDenominations,
			}}
		>
			{children}
		</CambiappContext.Provider>
	);
};

export interface Appointment {
	id: number;
	date: string;
	nickname: string;
	reservationHour: string;
	creationAt: string;
	updatedAt: string;
}

export interface Publication {
	id: number;
	type: ListingType;
	status: string;
	nickName: string;
	unitPrice: number;
	commission: number;
	minimunDenominationToReceive: string;
	advanceMinimumRequired: string;
	appointment: Appointment;
	isArchived: boolean;
	isDiscarded: boolean;
	totalAmount: number;
	total: number;
	currencyToReceive: Currency;
	currencyToGive: Currency;
	creationAt: string;
	updatedAt: string;
	isPartialAllowed: boolean;
	minimunPartialAmount: number;
	[key: string]: any;
}

export interface CompletedPublication extends Publication {
	completedDate: string;
	confirmationDate: string;
	spread: string;
	transactionId: string;
}

export enum ListingType {
	SALE = 'VENTA',
	PURCHASE = 'COMPRA',
}

export enum Currency {
	USD = 'USD',
	ARS = 'ARS',
	EUR = 'EUR',
	BRL = 'BRL',
}

export interface ListingShifts {
	isAvailable: boolean;
	id: number;
	date: string;
	shift: string;
	creationAt: string;
	updatedAt: string;
}

export interface ListingBatches {
	id: number;
	amount: number;
	billVersion: string;
	billDenomination: string;
	billColor: string;
	billStatus: string;
	creationAt: string;
	updatedAt: string;
}

import { useContext, useEffect } from 'react';
import { TableRow, TableCell as Cell, Typography } from '@mui/material';
import {
	CambiappContext,
	CambiappContextType,
} from '../../context/cambiapp.context';
import { CompletedPublication } from '../../interfaces/publication.interface';
import moment from 'moment';
import {
	currenciesFormater,
	decimalFormater,
} from '../../utils/currencies/currencies-formater';
import { convertTime } from '../../utils/date';

interface ITableComponent {
	children: CompletedPublication;
}

export const TableBodyCompleted = ({ children }: ITableComponent) => {
	const { statuses, currency } = useContext(
		CambiappContext,
	) as CambiappContextType;

	useEffect(() => {
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [statuses]);

	return (
		<TableRow>
			<Cell>
				<Typography>
					{currenciesFormater(children.listingBatches[0].amount)}
				</Typography>
				<Typography>
					{children.listingBatches[1]?.amount &&
						currenciesFormater(children.listingBatches[1].amount)}
				</Typography>
			</Cell>
			<Cell>
				<Typography>
					{children.listingBatches[0].billDenomination.split('-')[0]}
				</Typography>
				<Typography>
					{children.listingBatches[1]?.billDenomination.split('-')[0]}
				</Typography>
			</Cell>
			<Cell>
				<Typography sx={{ color: children.listingBatches[0].billColor }}>
					{children.listingBatches[0].billVersion.split('/')[0]}
				</Typography>
				<Typography sx={{ color: children.listingBatches[1]?.billColor }}>
					{children.listingBatches[1]?.billVersion.split('/')[0]}
				</Typography>
			</Cell>
			<Cell>
				<Typography>
					{statuses.data &&
					statuses.data.indexOf(children.listingBatches[0].billStatus) >= 0
						? statuses.data.indexOf(children.listingBatches[0].billStatus) + 1
						: null}
				</Typography>
				<Typography>
					{statuses.data &&
					children.listingBatches[1] &&
					statuses.data.indexOf(children.listingBatches[1].billStatus) >= 0
						? statuses.data.indexOf(children.listingBatches[1].billStatus) + 1
						: null}
				</Typography>
			</Cell>
			<Cell>
				{currency} {currenciesFormater(children.totalAmount)}
			</Cell>
			<Cell>{currenciesFormater(children.unitPrice, 'ARS')}</Cell>
			<Cell>{decimalFormater(children.unitPrice * children.totalAmount)}</Cell>
			<Cell>{moment(children.creationAt).format('DD/MM/YYYY HH:mm')}</Cell>
			<Cell sx={{ textAlign: 'center' }}>
				{children.nickName.split(' ').slice(0, -1).join(' ')}
			</Cell>
			<Cell>{children.type}</Cell>
			<Cell>
				{moment(children.confirmationDate).format('DD/MM/YYYY HH:mm')}
			</Cell>
			<Cell>{children.confirmationOperator}</Cell>
			<Cell>{children.confirmationDecision}</Cell>
			<Cell>
				{children.transactionId.slice(children.transactionId.length - 6)}
			</Cell>
			<Cell>
				{children.appointment?.date &&
					moment(children.appointment.date)
						.add(3, 'hours')
						.format('DD/MM/YYYY')}{' '}
				{convertTime(children.appointment?.reservationHour, 'ARG')}
			</Cell>
			<Cell>
				{children.completedDate &&
					moment(children.completedDate).format('DD/MM/YYYY HH:mm')}
			</Cell>
		</TableRow>
	);
};

import {
	Table as $Table,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell as Cell,
	Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import { CompletedPublication } from '../../interfaces/publication.interface';
import { TableBodyCompleted } from './table-row-component';

interface ITable {
	tableHeader: any;
	tableBody: CompletedPublication[];
}

const StyledTable = styled($Table)({
	thead: {
		tr: {
			th: {
				color: 'white',
				padding: '4px',
				textAlign: 'center',
				border: '1px solid black',
			},
		},
		backgroundColor: '#666666',
	},
	tbody: {
		backgroundColor: '#191919',
		tr: {
			td: {
				padding: '4px',
				textAlign: 'center',
				border: '1px solid black',
			},
		},
	},
});

const Table = ({ tableHeader, tableBody }: ITable) => {
	return (
		<TableContainer
			component={Paper}
			sx={{ marign: '8px', whiteSpace: 'nowrap' }}
		>
			<StyledTable aria-label="simple-table">
				<TableHead>
					{tableHeader.map((headerRow: any, index: any) => {
						return (
							<TableRow key={`header-${index}`}>
								{headerRow.map((tab: any, index: any) => {
									return (
										<Cell
											key={`cell-${index}`}
											colSpan={tab.colSpan}
											rowSpan={tab.rowSpan}
											sx={{ backgroundColor: tab.backgroundColor }}
											align="center"
										>
											{tab.title}
										</Cell>
									);
								})}
							</TableRow>
						);
					})}
				</TableHead>
				<TableBody>
					{tableBody.map((publication) => {
						return (
							<TableBodyCompleted key={publication.id}>
								{publication}
							</TableBodyCompleted>
						);
					})}
				</TableBody>
			</StyledTable>
		</TableContainer>
	);
};

export default Table;

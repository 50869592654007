// import firebase from "firebase";
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging/sw';
import { getToken } from 'firebase/messaging';

const app = initializeApp({
	apiKey: 'AIzaSyALg19LppBrJrPPowNRYq3NJCmHCBLVNJA',
	authDomain: 'cambiapp-f15d2.firebaseapp.com',
	projectId: 'cambiapp-f15d2',
	storageBucket: 'cambiapp-f15d2.appspot.com',
	messagingSenderId: '1031312770274',
	appId: '1:1031312770274:web:89a440395653da89e34abb',
	measurementId: 'G-HEGL1KZ003',
});

export const initializeFirebase = () => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.register('./firebase-messaging-sw.js')
			.then((registration) => {
				console.log('Registration successful, scope is:', registration.scope);
			})
			.catch((err) => {
				console.log('Service worker registration failed, error:', err);
			});
	}
	askForPermissioToReceiveNotifications();
};

export const askForPermissioToReceiveNotifications = async () => {
	const messaging = getMessaging(app);
	try {
		const notificationToken = await getToken(messaging, {
			vapidKey:
				'BAK0REzfshJzO19g0RteTklQZtexYwK4HpqVFXWi1JP4uiF9qGugH6JHmNN1Hwl5N3y4JZ5jgvxL3UsD1xfHwew',
		});
		localStorage.setItem('notificationToken', notificationToken);
		return notificationToken;
	} catch (error) {
		console.error(error);
		return error;
	}
};

import {
	Box,
	FormControlLabel,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
	IVersion,
} from '../../../context/cambiapp.context';
import { CommonButton } from '../../button/common-button';
import { InformationTooltip } from '../../tooltip/information-tooltip';

export const FirstStepComponent = ({ nextStep }: any) => {
	const {
		statuses,
		newBuyOrder,
		setNewBuyOrder,
		denominations,
		availableUsdVersions,
		setAvailableUsdVersions,
		currency,
	} = useContext(CambiappContext) as CambiappContextType;
	const [amount, setAmount] = useState(newBuyOrder.batch?.amount);
	const [denomination, setDenomination] = useState<
		{ name: string; value: number } | undefined
	>(newBuyOrder.batch?.denomination);
	const [version, setVersion] = useState<IVersion>();
	const [availableVersions, setAvailableVersions] = useState<IVersion[]>([]);
	const [status, setStatus] = useState(newBuyOrder.batch?.status);

	const handleAmountChange = (e: any) => {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === '' || regex.test(e.target.value)) {
			setAmount(parseInt(e.target.value));
		}
	};

	const handleNextStep = () => {
		if (amount && denomination && version && status && currency) {
			if (currency === 'BRL' && amount >= 500) {
				setNewBuyOrder({
					...newBuyOrder,
					type: 'COMPRA',
					batch: {
						amount,
						denomination,
						version,
						status,
					},
				});
				nextStep();
			} else if (currency !== 'BRL' && amount >= 100) {
				setNewBuyOrder({
					...newBuyOrder,
					type: 'COMPRA',
					batch: {
						amount,
						denomination,
						version,
						status,
					},
				});
				nextStep();
			} else {
				if (currency === 'BRL') {
					alert(
						`La publicación debe tener una cantidad igual o mayor a ${currency} 500.`,
					);
				} else {
					alert(
						`La publicación debe tener una cantidad igual o mayor a ${currency} 100.`,
					);
				}
			}
		}
	};

	useEffect(() => {
		setAvailableUsdVersions(availableVersions);
	}, [
		availableVersions,
		setAvailableUsdVersions,
		availableUsdVersions,
		denominations,
	]);

	useEffect(() => {
		if (!denominations.isLoading && denominations.data?.length === 0)
			denominations.refetch();
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [denominations, statuses]);

	useEffect(() => {
		if (currency !== 'USD') {
			setVersion(availableVersions[0]);
			setStatus(`${1}-${statuses.data?.[0]}`);
		}
	}, [currency, availableVersions, statuses.data]);

	return (
		<Box
			sx={{
				margin: '0 50px',
				padding: '25px 0',
				display: 'flex',
				flexDirection: 'column',
				rowGap: '25px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					columnGap: '15px',
				}}
			>
				<Typography variant="h6">Ingrese la información del lote</Typography>
				<InformationTooltip
					title={
						'Podés cargar en ésta publicación sólo 1 lote de determinada cantidad, denominación, versión y/o estado. La denominación, versión y estado que indiques para los billetes de ésta publicación se considerarán “mínimas”. Por lo tanto se entenderá que, al concretarse la operación, el vendedor podrá entregarte billetes en iguales o en mejores condiciones que las publicadas, en cualquier proporción. Se considerará que están en mejores condiciones cuando son de mayor denominación, versión más nueva, o se encuentran en mejor estado que lo especificado en la publicación.'
					}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					columnGap: '15px',
				}}
			>
				<Typography sx={{ fontWeight: 800 }}>
					Indicá la cantidad de {currency} que querés comprar
				</Typography>
				<InformationTooltip
					title={`La cantidad mínima es de  ${currency} ${
						currency === 'BRL' ? '500' : '100'
					}. No se pueden ingresar centavos.`}
				/>
			</Box>
			<TextField
				onChange={(e) => handleAmountChange(e)}
				variant="standard"
				sx={{
					backgroundColor: '#000000',
					padding: '10px',
					borderRadius: '5px',
					color: 'white',
				}}
				placeholder="0,00"
				value={amount || ''}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">{currency}</InputAdornment>
					),
					disableUnderline: true,
				}}
			/>
			{denominations.isSuccess && denominations.data.length > 0 && (
				<>
					<Typography sx={{ fontWeight: 800 }}>
						Seleccioná la denominación mínima de los billetes {currency} que
						demandás
					</Typography>
					<Select
						value={denomination?.value || 0}
						displayEmpty
						variant="standard"
						sx={{
							backgroundColor: '#000000',
							padding: '10px',
							borderRadius: '5px',
						}}
						disableUnderline
					>
						<MenuItem value={0} sx={{ display: 'none' }}>
							Seleccionar
						</MenuItem>
						{denominations.data
							.sort((a, b) => b.amount - a.amount)
							.map((usdDenomination) => {
								return (
									<MenuItem
										value={usdDenomination.amount}
										key={usdDenomination.id}
										onClick={() => {
											setAvailableVersions(usdDenomination.versions);
											setDenomination({
												name: usdDenomination.name,
												value: usdDenomination.amount,
											});
										}}
									>
										{usdDenomination.name}
									</MenuItem>
								);
							})}
					</Select>

					{currency === 'USD' && (
						<RadioGroup>
							{availableVersions.length > 0 && (
								<>
									<Typography sx={{ fontWeight: 800 }}>
										Seleccioná la versión de los billetes {currency} que
										demandás
									</Typography>
									{availableVersions.map((availableVersion) => {
										return (
											<FormControlLabel
												sx={{ color: availableVersion.color }}
												value={availableVersion.name}
												control={
													<Radio
														checked={version?.name === availableVersion.name}
													/>
												}
												label={`${availableVersion.name} ${availableVersion.description}`}
												key={availableVersion.id}
												onClick={() => setVersion(availableVersion)}
											/>
										);
									})}
								</>
							)}
						</RadioGroup>
					)}
				</>
			)}
			{version && statuses.isSuccess && (
				<>
					<RadioGroup>
						<Typography sx={{ fontWeight: 800 }}>
							Seleccioná el estado en que requerís que se encuentren los
							billetes {currency} que querés comprar
						</Typography>
						{statuses.data.map((eachStatus, index) => {
							return (
								<FormControlLabel
									key={index}
									value={index + 1}
									control={
										<Radio checked={status === `${index + 1}-${eachStatus}`} />
									}
									label={`${index + 1}-${eachStatus}`}
									onClick={() => setStatus(`${index + 1}-${eachStatus}`)}
								/>
							);
						})}
					</RadioGroup>
				</>
			)}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Typography sx={{ borderBottom: '1px solid' }}></Typography>
				<CommonButton
					action={handleNextStep}
					sxBgcolor={'#05A550'}
					maxHeight="40px"
				>
					<Typography fontSize={12} color={'white'} fontWeight={600}>
						CONTINUAR
					</Typography>
				</CommonButton>
			</Box>
		</Box>
	);
};

import { Box } from '@mui/material';

interface CommonModalContainerProps {
	children: React.ReactNode;
}

export const CommonModalContainer = ({
	children,
}: CommonModalContainerProps) => {
	return (
		<Box sx={{ display: 'flex', columnGap: '12px', margin: '36px 0' }}>
			{children}
		</Box>
	);
};

const styles: React.CSSProperties = {
	position: 'absolute',
	height: '23px',
	width: '20px',
	left: '90px',
	top: '-5px',
};

export const FloatingGuaranteeShield = () => {
	return (
		<div>
			<img style={styles} src={'/shield_with_check.png'} alt="" />
		</div>
	);
};

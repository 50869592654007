import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Divider, Drawer, Link, List, ListItem } from '@mui/material';
import { UserTooltip } from './tooltip/user-tooltip';
import { getUserEmailFromToken } from '../utils/user/user';

export const TopBar = () => {
	const location = useLocation();
	let title;
	let textColor = '#4fc3f7';
	let bgColor = 'inherit';
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const [userEmail, setUserEmail] = useState(String);
	const typeLocation = location.pathname.split('/')[1];

	useEffect(() => {
		const token = localStorage.getItem('firebaseToken');

		if (token) {
			setUserEmail(getUserEmailFromToken(token));
		}
	}, []);

	const handleCloseSession = () => {
		localStorage.clear();
		setSidebarOpen(false);
	};

	switch (typeLocation) {
		case 'sell':
			title = 'Panel de Ventas';
			textColor = 'black';

			break;
		case 'buy':
			title = 'Panel de Compras';
			textColor = 'black';

			break;
		default:
			title = (
				<div
					style={{
						margin: 0,
						padding: 0,
						maxHeight: '50px',
						maxWidth: '100px',
					}}
				>
					<img src={'/cambiapp_logo.png'} alt="Logo" style={{ margin: 0 }} />
				</div>
			);

			bgColor = 'inherit';
			break;
	}

	return (
		<>
			<Drawer
				anchor={'left'}
				open={isSidebarOpen}
				onClose={() => setSidebarOpen(false)}
			>
				<Box
					sx={{
						height: '100vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<List
						sx={{
							minWidth: '300px',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Link
							href="/"
							onClick={() => setSidebarOpen(false)}
							style={{ textDecoration: 'none' }}
						>
							<ListItem
								style={{
									color: 'whitesmoke',
									textDecoration: 'none',
									fontSize: '24px',
									margin: '12px 0',
									fontFamily: 'roboto',
								}}
							>
								Menu principal
							</ListItem>
							<Divider />
						</Link>
						<Link
							href="/faq"
							onClick={() => setSidebarOpen(false)}
							style={{ textDecoration: 'none' }}
						>
							<ListItem
								style={{
									color: 'whitesmoke',
									textDecoration: 'none',
									fontSize: '24px',
									margin: '12px 0',
									fontFamily: 'roboto',
								}}
							>
								Manual de usuario
							</ListItem>
							<Divider />
						</Link>
					</List>
					<Link
						href="/login"
						onClick={handleCloseSession}
						style={{ textDecoration: 'none' }}
					>
						<Divider />
						<ListItem
							style={{
								color: 'whitesmoke',
								textDecoration: 'none',
								fontSize: '24px',
								margin: '12px 0',
								fontFamily: 'roboto',
							}}
						>
							Cerrar sesión
						</ListItem>
					</Link>
				</Box>
			</Drawer>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '10px',
					backgroundColor: '#F1F1F2',
					color: '#2e2e2e',
				}}
			>
				<MenuIcon fontSize="large" onClick={() => setSidebarOpen(true)} />
				<Typography
					align="center"
					variant="h5"
					noWrap
					sx={{ fontWeight: 400, color: textColor }}
				>
					{title}
				</Typography>
				<UserTooltip title={userEmail} />
			</Box>
		</>
	);
};

import React, { ReactElement, useContext } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { StyledTableCell } from './batch-table-cell.component';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';

interface BatchTableProps {
	children: ReactElement | ReactElement[] | any[];
}

export const BatchTable = ({ children }: BatchTableProps) => {
	const { currency } = useContext(CambiappContext) as CambiappContextType;

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell sx={{ textAlign: 'center' }}>
							Total ({currency})
						</StyledTableCell>
						<StyledTableCell sx={{ textAlign: 'center' }}>
							Denominación
						</StyledTableCell>
						<StyledTableCell sx={{ textAlign: 'center' }}>
							Versión
						</StyledTableCell>
						<StyledTableCell sx={{ textAlign: 'center' }}>
							Estado
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>{children}</TableBody>
			</Table>
		</TableContainer>
	);
};

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItem,
	ListItemText,
	Modal,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { currenciesFormater } from '../utils/currencies/currencies-formater';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../context/cambiapp.context';
import { StyledTableCell } from './new-order-steps/buy';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import moment from 'moment';
import { asyncHandler } from '../utils/async-handler';
import { convertTime } from '../utils/date';
import Countdown from 'react-countdown';
import { CommonTooltip } from './tooltip/common-tooltip';
import { CommonButton } from './button/common-button';
import { FloatingGuaranteeShield } from './guarantee-shield/floating-guarantee-shield';
import { TextualGuaranteeShield } from './guarantee-shield/textual-guarantee-shield';
import { Currency } from '../interfaces/publication.interface';

interface IPublication {
	children: { [key: string]: any };
	currency: Currency;
}

interface IIdenttificationForm {
	type: 'circle' | 'square';
	bgColor1: string;
	bgColor2?: string;
	children: string;
}

export const IdentificationForm = ({
	type,
	bgColor1,
	bgColor2,
	children,
}: IIdenttificationForm) => {
	const blockStyle = {
		width: '0',
		height: '0',
		borderRight: `14px solid ${bgColor1}`,
		borderTop: '14px solid transparent',
		borderBottom: '14px solid transparent',
		position: 'absoulte' as unknown as undefined,
		right: '20px',
	};
	const blockStyle2 = {
		width: '0',
		height: '0',
		borderLeft: `14px solid ${bgColor2 || bgColor1}`,
		borderTop: '14px solid transparent',
		borderBottom: '14px solid transparent',
		position: 'absoulte' as unknown as undefined,
		left: '20px',
	};
	const circleStyle = {
		display: 'inline-block',
		width: '12px',
		height: '24px',
		backgroundColor: bgColor1,
		borderRadius: '15px 0 0 15px',
		textAlign: 'center' as unknown as undefined,
		position: 'absoulte' as unknown as undefined,
	};
	const circleStyle2 = {
		display: 'inline-block',
		width: '12px',
		height: '24px',
		backgroundColor: bgColor2 || bgColor1,
		borderRadius: '0 15px 15px 0',
		textAlign: 'center' as unknown as undefined,
		position: 'absoulte' as unknown as undefined,
	};
	return (
		<span
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				maxWidth: '25%',
			}}
		>
			<span style={type === 'square' ? blockStyle : circleStyle} />
			<span style={{ position: 'absolute' }}>{children}</span>
			<span style={type === 'square' ? blockStyle2 : circleStyle2} />
		</span>
	);
};

export const ConfirmedPublication = ({ children, currency }: IPublication) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [isDialogAbortOpen, setDialogAbortOpen] = useState(false);
	const { nickName, totalAmount, unitPrice, type } = children;
	const { statuses, currencies } = useContext(
		CambiappContext,
	) as CambiappContextType;
	const usdText = currenciesFormater(totalAmount);
	const arsText = currenciesFormater(unitPrice, 'ARS');
	const itemText = children.listingBatches.map((batch: any) => {
		return {
			color: currency === 'USD' ? batch.billColor : '#666666',
			status: statuses.data && statuses.data.indexOf(batch.billStatus),
		};
	});

	const currencyColor = useMemo(() => {
		if (Array.isArray(currencies?.data)) {
			const foundCurrency = currencies.data.find((c) => c.code === currency);
			return foundCurrency ? foundCurrency.color : '#48887B';
		}
		return '#48887B';
	}, [currency, currencies?.data]);

	const itemToShow = (
		<span
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-evenly',
			}}
		>
			<em style={{ fontStyle: 'normal', width: '40%' }}>
				{currency} {usdText[1]}
			</em>
			<IdentificationForm
				type={type === 'COMPRA' ? 'square' : 'circle'}
				bgColor1={itemText[0]?.color}
				bgColor2={itemText[1]?.color || itemText[0]?.color}
			>
				{itemText.map((item: any) => item.status + 1)}
			</IdentificationForm>
			<em style={{ fontStyle: 'normal', width: '40%' }}>
				{`${arsText[0]} ${arsText[1]}`}
			</em>
		</span>
	);

	const handleRequestChange = () =>
		asyncHandler.requestChanges(children.id, () => {
			setDialogOpen(false);
			setModalOpen(false);
		});

	const handleRequestAbort = () =>
		asyncHandler.requestAbort(children.id, () => {
			setDialogAbortOpen(false);
			setModalOpen(false);
		});

	const expirationDate = useMemo(() => {
		const res = moment(children.appointment.date)
			.add(children.appointment.reservationHour.split(':')[0], 'hours')
			.add(children.appointment.reservationHour.split(':')[1], 'minutes');

		return res.toDate();
	}, [children.appointment.date, children.appointment.reservationHour]);

	useEffect(() => {
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [statuses]);

	return (
		<>
			<Modal
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					rowGap: '25px',
					margin: '0 auto',
					backdropFilter: 'blur(5px)',
				}}
			>
				<>
					<Dialog
						open={isDialogOpen}
						onClose={() => setDialogOpen(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							¿Estás seguro de solicitar cambios?
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Estas solicitando cambios en una operación ya confirmada, lo
								cual es un incumplimiento de lo acordado, y tu identidad real y
								contacto telefónico serán proporcionados al comprador, si éste
								lo solicita. Los cambios deberán fundarse en caso fortuito o
								fuerza mayor comprobable, es decir, por un problema grave e
								imprevisible que realmente justifique tal decisión. En caso
								contrario serás EXPULSADO de CambiApp. Deberás comunicarte
								telefónicamente con el Administrador para informar los cambios e
								intentar cerrar igualmente el trato.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setDialogOpen(false)} color="error">
								NO
							</Button>
							<Button onClick={handleRequestChange} autoFocus>
								SI
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={isDialogAbortOpen}
						onClose={() => setDialogAbortOpen(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							¿Estás seguro de que quiere abortar la operación?
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description"></DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setDialogAbortOpen(false)} color="error">
								NO
							</Button>
							<Button onClick={handleRequestAbort} autoFocus>
								SI
							</Button>
						</DialogActions>
					</Dialog>
					<Box
						sx={{
							maxWidth: 'max-content',
							maxHeight: 'max-content',
						}}
					>
						<Box
							sx={{
								margin: 0,
								padding: '10px 20px',
								backgroundColor: currencyColor,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '15px 15px 0 0',
								position: 'relative',
							}}
						>
							<Typography>{children.nickName}</Typography>
							<Typography>{children.action.toUpperCase()}</Typography>
							<CircleRoundedIcon
								sx={{
									position: 'absolute',
									top: '16px',
									right: '16px',
									fill: 'white',
									fontSize: '28px',
								}}
							/>
							<CancelIcon
								sx={{
									position: 'absolute',
									top: '15px',
									right: '15px',
									color: '#191919',
									fontSize: '30px',
								}}
								onClick={() => setModalOpen(false)}
							/>
						</Box>
						<TableContainer component={Paper} sx={{ marign: '8px' }}>
							<Table>
								<TableHead>
									<TableRow>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Total ({currency})
										</StyledTableCell>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Denominación
										</StyledTableCell>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Versión
										</StyledTableCell>
										<StyledTableCell sx={{ textAlign: 'center' }}>
											Estado
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{children.listingBatches?.map((batch: any, index: number) => {
										return (
											<TableRow key={index}>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{currenciesFormater(batch.amount)}
												</StyledTableCell>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{batch.billDenomination.split('-')[0]}
												</StyledTableCell>
												<CommonTooltip title={batch.billVersion.split('/')[1]}>
													<StyledTableCell
														sx={{
															textAlign: 'center',
															color: batch.billColor,
														}}
													>
														{batch.billVersion.split('/')[0]}
													</StyledTableCell>
												</CommonTooltip>
												<CommonTooltip title={batch.billStatus}>
													<StyledTableCell sx={{ textAlign: 'center' }}>
														{statuses.data &&
															statuses.data.indexOf(batch.billStatus) + 1}
													</StyledTableCell>
												</CommonTooltip>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(4, 1fr)',
								gridTemplateRows: 'repeat(4, 1fr)',
								gridColumnGap: '20px',
								gridRowGap: '10px',
								margin: 0,
								padding: '20px 20px',
								bgcolor: '#191919',
							}}
						>
							<Typography
								sx={{
									width: 'max-content',
									height: 'max-content',
									gridArea: '1 / 1 / 2 / 3',
									paddingTop: '10px',
								}}
							>
								Cantidad total
							</Typography>
							<Box
								sx={{
									borderRadius: '5px',
									padding: '5px 10px',
									width: '100%',
									height: 'max-content',
									gridArea: '1 / 3 / 2 / 5',
									bgcolor: '#000000',
									marginTop: '10px',
								}}
							>
								<Typography
									sx={{
										width: 'auto',
										height: 'max-content',
										textAlign: 'right',
									}}
								>
									{currency} {currenciesFormater(children.totalAmount)}
								</Typography>

							</Box>
							<Typography
								sx={{
									width: 'max-content',
									height: 'max-content',
									gridArea: '2 / 1 / 3 / 3',
								}}
							>
								Precio unitario
							</Typography>
							<Box
								sx={{
									borderRadius: '5px',
									padding: '5px 10px',
									width: '100%',
									height: 'max-content',
									gridArea: '2 / 3 / 3 / 5',
									bgcolor: '#000000',
								}}
							>
								<Typography
									sx={{
										width: 'auto',
										height: 'max-content',
										textAlign: 'right',
									}}
								>
									{currenciesFormater(children.unitPrice, 'ARS')}
								</Typography>
							</Box>
							<Typography
								sx={{
									width: 'max-content',
									height: 'max-content',
									gridArea: '3 / 1 / 4 / 3',
								}}
							>
								Total
							</Typography>
							<Box
								sx={{
									borderRadius: '5px',
									padding: '5px 10px',
									width: '100%',
									height: 'max-content',
									gridArea: '3 / 3 / 4 / 5',
									bgcolor: '#000000',
								}}
							>
								<Typography
									sx={{
										width: 'auto',
										height: 'max-content',
										textAlign: 'right',
									}}
								>
									ARS{' '}
									{currenciesFormater(
										children.totalAmount * children.unitPrice,
									)}
								</Typography>
							</Box>
							<Typography
								fontSize={15}
								style={{
									gridArea: '4 / 1 / 5 / 4',
									maxWidth: '260px',
								}}
							>
								Denominación mínima de billetes pesos a entregar al vendedor:
							</Typography>
							<Typography
								fontSize={14}
								sx={{
									margin: 'auto',
									marginLeft: '-25px',
									bgcolor: '#000000',
									fontWeight: '600',
									color: 'white',
									padding: '8px 10px',
									gridArea: '4 / 4 / 5 / 5',
									borderRadius: '5px',
								}}
							>
								{children.minimumDenominationToReceive}
							</Typography>
							{children.isGuaranteed && <TextualGuaranteeShield />}
						</Box>
						<Box
							sx={{
								margin: 0,
								padding: '10px 20px',
								backgroundColor: currencyColor,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								borderRadius: '0 0 15px 15px',
							}}
						>
							<Box>
								{!children.requestChanges && (
									<Typography>
										Confirmado:{' '}
										{moment(children.confirmationDate)
											.add(-3, 'hours')
											.format('DD/MM/YYYY')}{' '}
										{convertTime(
											children.confirmationDate.split('T')[1].slice(0, 5),
											'ARG',
										)}
									</Typography>
								)}
								{children.requestChanges && (
									<Typography>
										Solicitud cambios:{' '}
										{moment(children.dateChangesRequested)
											.add(-3, 'hours')
											.format('DD/MM/YYYY')}{' '}
										{convertTime(
											children.confirmationDate.split('T')[1].slice(0, 5),
											'ARG',
										)}
									</Typography>
								)}
								<Typography>
									Cód. de op:{' '}
									{children.transactionId.slice(
										children.transactionId.length - 6,
									)}
								</Typography>
								<Typography>
									Transacción:
									{moment(children.appointment.date)
										.add(3, 'hours')
										.format('DD/MM/YYYY')}{' '}
									{convertTime(children.appointment.reservationHour, 'ARG')}
								</Typography>
								{expirationDate && (
									<>
										<Countdown
											date={expirationDate}
											zeroPadTime={2}
											daysInHours
											renderer={(props) => (
												<Typography>
													La transacción se hace en: {props.formatted.hours}:
													{props.formatted.minutes}:{props.formatted.seconds}
												</Typography>
											)}
										/>
									</>
								)}
								<Typography sx={{ fontWeight: 800, textAlign: 'center' }}>
									Historial:
								</Typography>
								<TableContainer component={Paper}>
									<Table sx={{ borderColor: '#191919' }}>
										<TableBody>
											<TableRow>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{moment(children.creationAt).format(
														'DD/MM/YYYY HH:mm',
													)}
												</StyledTableCell>
												<StyledTableCell sx={{ textAlign: 'center' }}>
													{children.createdBy}
													<br />
													{children.createdBy === 'Yo' ? 'Publiqué' : 'Publicó'}
												</StyledTableCell>
												<StyledTableCell
													sx={{
														textAlign: 'center',
														backgroundColor:
															children.type === 'COMPRA'
																? '#4fc3f7'
																: '#81c784',
													}}
												>
													{children.type}
												</StyledTableCell>
											</TableRow>
											{children.requestChanges && (
												<TableRow>
													<StyledTableCell sx={{ textAlign: 'center' }}>
														{moment(children.appointment.creationAt).format(
															'DD/MM/YYYY HH:mm',
														)}
													</StyledTableCell>
													<StyledTableCell sx={{ textAlign: 'center' }}>
														{children.appointment.nickname}
													</StyledTableCell>
													<StyledTableCell
														sx={{
															textAlign: 'center',
															backgroundColor:
																children.type === 'COMPRA'
																	? '#81c784'
																	: '#4fc3f7',
														}}
													>
														{children.appointment.nickname === 'Yo' &&
															(children.type === 'COMPRA' ? 'Vendí' : 'Compré')}
														{children.appointment.nickname !== 'Yo' &&
															(children.type === 'COMPRA'
																? 'Vendió'
																: 'Compró')}
													</StyledTableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Box>
					</Box>
					{!children.requestChanges && (
						<div>
							<CommonButton
								variant="contained"
								color="secondary"
								minWidth="200px"
								sxBgcolor="#191919"
								sxColor="whitesmoke"
								action={() => setDialogOpen(true)}
							>
								Solicitar cambios
							</CommonButton>
							<CommonButton
								variant="contained"
								sxBgcolor="#ED3237"
								color="error"
								minWidth="200px"
								margin="10px 0 0 0"
								action={() => setDialogAbortOpen(true)}
							>
								Abortar
							</CommonButton>
						</div>
					)}
				</>
			</Modal>
			<ListItem
				sx={{
					'textAlign': 'center',
					'padding': 0,
					'display': 'flex',
					'alignItems': 'stretch',
					'margin': 0,
					'rowGap': '5px',
					':hover': {
						cursor: 'pointer',
					},
				}}
				onClick={() => setModalOpen(true)}
			>
				{children.isGuaranteed && <FloatingGuaranteeShield />}
				<ListItemText
					primary={
						<Typography
							lineHeight={1}
							fontSize={children.requestChanges ? 10 : 12}
							fontWeight={800}
						>
							{children.action.toUpperCase()}
						</Typography>
					}
					secondary={
						<Typography lineHeight={1} fontSize={12} fontWeight={400}>
							{nickName.length > 10 ? nickName.slice(0, 9) + '...' : nickName}
						</Typography>
					}
					sx={{
						margin: 0,
						padding: '2.5px 0',
						backgroundColor: currencyColor,
						display: 'flex',
						flexDirection: 'column-reverse',
						justifyContent: 'center',
						borderRadius: '2.5px 0 0 2.5px',
						minWidth: '100px',
						maxWidth: '74px',
						textOverflow: 'ellipsis',
					}}
				/>
				<ListItemText
					secondary={
						<Typography lineHeight={1} fontSize={14} fontWeight={500}>
							{itemToShow}
						</Typography>
					}
					sx={{
						margin: 0,
						padding: '2.5px',
						backgroundColor: '#000000',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				/>
				{expirationDate && (
					<>
						<Countdown
							date={expirationDate}
							zeroPadTime={2}
							daysInHours
							renderer={(props) => (
								<div
									style={{
										margin: 0,
										backgroundColor: currencyColor,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										borderRadius: '0 5px 5px 0',
										width: '50px',
										padding: '2.5px 0',
									}}
								>
									<Typography lineHeight={1} fontSize={12}>
										{props.formatted.hours}:{props.formatted.minutes}:
										{props.formatted.seconds}
									</Typography>
								</div>
							)}
						/>
					</>
				)}
			</ListItem>
		</>
	);
};

import { TableRow, TextField, IconButton } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { CommonTooltip } from '../../../components/tooltip/common-tooltip';
import { StyledTableCell } from './batch-table-cell.component';

interface TableRowProps {
	amount: number;
	denomination: string;
	descriptionTooltip?: string;
	versionColor: string;
	versionName: string;
	statusTooltip?: string;
	statusName: number | undefined;
	onDelete?: () => void;
	data: { isPartialAllowed: boolean; minimunPartialAmount: number };
	onError?: (errorInfo: { message: string } | null) => void;
	setEditableAmount: (editableAmount: number) => void;
}

export const BatchTableRow = ({
	amount,
	denomination,
	descriptionTooltip,
	versionColor,
	versionName,
	statusTooltip,
	statusName,
	onDelete,
	data,
	onError,
	setEditableAmount,
}: TableRowProps) => {
	const [amountInModal, setAmountInModal] = useState(amount.toString());

	const validateAmount = (newAmount: number) => {
		let errorInfo = null;
		if (newAmount < 100) {
			errorInfo = { message: 'El monto debe ser mayor o igual a 100' };
		} else if (data.isPartialAllowed && newAmount <= data.minimunPartialAmount) {
			errorInfo = {
				message: 'El monto debe ser mayor al monto parcial',
			};
		}
		return errorInfo;
	};

	const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newAmount = parseFloat(event.target.value);
		setEditableAmount(newAmount);
		setAmountInModal(newAmount.toString());
		const errorInfo = validateAmount(newAmount);
		onError?.(errorInfo);
	};

	const handleDelete = () => {
		onDelete?.();
	};

	return (
		<TableRow sx={{ textAlign: 'center', height: '60px' }}>
			<StyledTableCell sx={{ textAlign: 'center', position: 'relative' }}>
				<TextField
					type="number"
					value={amountInModal}
					onChange={handleAmountChange}
					sx={{ minWidth: '60px' }}
					inputProps={{ style: { textAlign: 'center', padding: 10 } }}
				/>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: 'center' }}>
				{denomination}
			</StyledTableCell>
			<CommonTooltip title={descriptionTooltip as string}>
				<StyledTableCell sx={{ textAlign: 'center', color: versionColor }}>
					{versionName}
				</StyledTableCell>
			</CommonTooltip>
			<CommonTooltip title={statusTooltip as string}>
				<StyledTableCell sx={{ textAlign: 'center' }}>
					{statusName || ''}
				</StyledTableCell>
			</CommonTooltip>
			<StyledTableCell sx={{ textAlign: 'center' }}>
				{onDelete && (
					<IconButton onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				)}
			</StyledTableCell>
		</TableRow>
	);
};

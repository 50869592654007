import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CommonButton } from '../../components/button/common-button';
import './faq.styles.css';

const FaqPage = () => {
	const navigate = useNavigate();
	return (
		<>
			<Box className="main-container">
				<CommonButton action={() => navigate('/')} margin="12px 0">
					{'< Volver'}
				</CommonButton>
				<Box className="header-container">
					<img src={'/cambiapp_logo.png'} alt="Logo" width={250} />
					<Box>
						<Typography variant="h4">MANUAL DE USUARIO</Typography>
						<Typography variant="h6">Preguntas Frecuentes</Typography>
						<Typography variant="subtitle1">
							Actualización: 04/09/2024
						</Typography>
					</Box>
				</Box>
				<Box className="body-container">
					<Typography variant="h4" className="first-section section-header">
						1. Aspectos generales
					</Typography>
					<Box className="section-container">
						<Typography variant="h5" className="first-section">
							1.1. ¿Qué es Cambiapp?
						</Typography>
						<Typography className="section-text">
						Es una plataforma que permite a su comunidad de usuarios realizar, entre sí, operaciones de compraventa física de divisas, tales como dólares estadounidenses, reales brasileños, y euros. Podrás utilizar Cambiapp tanto para vender como para comprar divisas. Y, una vez acordada una transacción en el sistema, el Administrador brindará a los operadores un espacio físico (Oficina de Administración) para realizar la operación de cambio, como así también el control de autenticidad e integridad de los billetes negociados, y del cumplimiento del acuerdo confirmado.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.2. ¿Cualquiera puede registrarse y/o acceder a Cambiapp?
						</Typography>
						<Typography className="section-text">
							No. La comunidad Cambiapp es cerrada y confidencial. El acceso y operación en la plataforma es exclusivo para usuarios
							<br />
							Los usuarios son registrados por el Administrador sin excepciones, a solicitud de los primeros, o por invitación del primero. 
							<br />
							El Administrador declara conocer a todos los usuarios del sistema, y mantiene registro de sus datos personales y de contacto. Esto reduce el riesgo de fraudes, y el de utilización de la App para fines diferentes a su objeto.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.3. ¿Cómo se opera?
						</Typography>
						<Typography className="section-text">
						Cambiapp funciona mediante publicaciones de venta (ofertas) y de compra (demandas) efectuadas por los operadores, las cuales pueden ser visualizadas aceptadas por la contraparte.
						<br />
						Una vez que la operación es acordada y confirmada en el sistema, comprador y vendedor deberán concurrir a la Oficina en la fecha y hora (cita) convenida, donde se realizará el intercambio físico de billetes.
						<br />
						En principio, no habrá interacción directa entre comprador y vendedor, siendo el Administrador el encargado de recibir y entregar las cantidades que correspondan a cada uno de ellos por separado, de acuerdo a lo convenido.

						</Typography>
						<Typography variant="h5" className="first-section">
							1.4. ¿Puedo mandar a otra persona a concretar la operación en la
							Casa, en mi representación?
						</Typography>
						<Typography className="section-text">
							Sí. Para ello, quién se presente a tal fin deberá mencionar tu
							nombre y exhibir imagen de la capsula de la operación confirmada.
							Sin perjuicio de ello, el Administrador podrá requerirle datos
							adicionales o realizar constataciones, con el fin de minimizar los
							riesgos de fraude o falsa representación.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.5. ¿Los otros usuarios conocerán mi identidad?
						</Typography>
						<Typography className="section-text">
							En principio no. Cambiapp mantendrá en absoluta confidencialidad
							todos los datos personales de sus usuarios, y sus operaciones.
							<br />
							Cuando efectúes una publicación o aceptes la publicación de otro
							operador, el sistema te asignará automáticamente un alias (nombre
							de fantasía) transitorio, que se vinculará exclusivamente a dicha
							publicación u operación. Y será ese alias el que verán los otros
							usuarios, manteniéndose siempre oculto y reservado tu nombre real.
							<br />
							Con cada nueva publicación que efectúes o aceptes, el sistema
							generará aleatoriamente un nuevo alias para identificarte.
							<br />
							Además, al concretarse la operación en la Oficina, se evitará el
							contacto directo entre los operadores, siendo el Administrador el
							encargado de recibir y entregar las cantidades correspondientes.
							<br />
							La única excepción a esta regla será cuando un operador incurra en
							el incumplimiento de una operación confirmada. En tal caso, y a
							pedido del operador damnificado, se le facilitarán los datos
							personales del incumplidor, incluyendo su domicilio y contacto
							telefónico.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.6. ¿Qué costo tiene Cambiapp?
						</Typography>
						<Typography className="section-text">
							La registración, ingreso y utilización de la plataforma son totalmente gratuitos.
							<br />
							Los precios que se muestran en las publicaciones tanto propias como de otros operadores, son siempre finales, y no se te requerirá ningún pago extra (si estas comprando), ni se te aplicará descuento alguno (si estas vendiendo), al concretarse la operación. 
						</Typography>
						<Typography variant="h5" className="first-section">
							1.7. ¿Puedo usar la plataforma para vender y comprar al “mismo
							tiempo”?
						</Typography>
						<Typography className="section-text">
							Sí. La Cambiapp te permite operar simultáneamente en posición vendedora y compradora, pudiendo tener publicaciones vigentes y/u operaciones confirmadas en ambos paneles. 
							<br />
							Operar simultáneamente en ambas posiciones puede ser útil cuando se transa con diferentes calidades de moneda. Por ejemplo, vendes “cara grande” y compras “azules”. 
							<br />
							En cualquier caso, te recomendamos que, al operar de esta manera, tomes las precauciones necesarias para evitar quedar en descubierto comercial ante la eventualidad de no poder concretar en ambas posiciones. Por ej. no poder cumplir con la compra comprometida, por no haber podido vender las divisas para obtener el dinero necesario.
							<br />
							Recordá que un incumplimiento por tal motivo se considera injustificado, y puede derivar en tu expulsión de la plataforma.

						</Typography>
						<Typography variant="h5" className="first-section">
							1.8. ¿Qué es el panel de operaciones?
						</Typography>
						<Typography className="section-text">
							El panel de operaciones, es la pantalla donde podrás ver el estado
							de tus publicaciones y operaciones.
							<br />
							Dependiendo de si ingresaste para vender o comprar, el sistema te ubicará en el Panel de Ventas, o en el Panel de Compras. 
							<br />
							Cada panel, sea de ventas o de compras, se dividirá en 4 áreas:
							<ul>
								<li>Publicaciones vigentes</li>
								<li>Operaciones confirmadas</li>
								<li>Operaciones concretadas</li>
								<li>Publicaciones y operaciones archivadas</li>
							</ul>
						</Typography>
						<Typography variant="h5" className="first-section">
							1.9. ¿Qué puedo ver y hacer en mi área de Publicaciones Vigentes?
						</Typography>
						<Typography className="section-text">
						Se mostrarán en esta área todas las publicaciones que pueden ser aceptadas. 
						<br />
						Si se trata de tu Panel de Ventas, podrás ver aquí todas las oportunidades de vender. Esto incluye tus propias publicaciones de venta, y también las publicaciones de compra efectuadas por otros operadores.
						<br />
						Y si se trata de tu Panel de Compras, verás todas las oportunidades de comprar. Esto incluye tus propias publicaciones de compra, y también las publicaciones de venta efectuadas por otros operadores.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.10. ¿Cómo se visualizan las publicaciones vigentes?
						</Typography>
						<Typography className="section-text">
							En Cambiapp, cada publicación vigente recibe el nombre de
							“cápsula”.
							<br />
							Las publicaciones vigentes se muestran inicialmente como una lista
							de cápsulas cerradas, que representan oportunidades de comprar o
							de vender, según cuál sea el panel.
							<br />
							Cada cápsula cerrada se visualiza como una barra horizontal, conteniendo los datos básicos de la publicación.
							<br />
							A la izquierda se identifica al operador. Si se menciona la palabra “Yo”, significa que son publicaciones propias (Por ej. “Yo VENDO” o “Yo COMPRO”), que pueden ser aceptadas por otros operadores. Y si aparece un alias, se trata de publicaciones de terceros (Por ej. “Oruga 22 COMPRA”), que vos podés aceptar.
							<br />
							Luego se muestra la cantidad total de la publicación. 
							<br />
							El símbolo central representa el tipo de publicación (círculo: venta y rombo: compra), su color indica la versión de los billetes (por ej., en caso de dólares, azul: nueva, verde: anterior y roja: vieja), y el número central indica su estado de conservación (1,2,3 o 4). 
							<br />
							En el sector derecho se muestra el precio unitario de las divisas ofrecidas o demandadas. Y al final (extremo derecho) se muestra en tiempo real, lo que falta para que venza la publicación. 
							<br />
							Para ver más detalles sobre la publicación (tales como la cantidad exacta de cada lote, la denominación mínima de las divisas, las citas alternativas, etc.), y/o tomar alguna decisión (comprar, vender, pausar, eliminar, etc.) sobre la misma, simplemente deberás tocarla, y la misma se abrirá. 
						</Typography>
						<Typography variant="h5" className="first-section">
							1.11. ¿Qué significa que una operación está confirmada?
						</Typography>
						<Typography className="section-text">
							Cuando un operador acepta la publicación creada por otro operador,
							está en dicho acto manifestando su conformidad con todas las
							condiciones publicadas y, por tanto, la compraventa queda
							automáticamente confirmada. Es decir, el trato quedó cerrado y
							ambas partes asumen el compromiso de dar cumplimiento a lo
							acordado.
							<br />
							Al momento de confirmar, el aceptante deberá seleccionar una de
							las citas de transacción alternativas propuestas por el emisor, la
							cual quedará fija a tales efectos.
							<br />
							Cuando la publicación es confirmada, sale del área de “Publicaciones Vigentes”, y pasa al área de “Operaciones Confirmadas”, restando sólo que dicha operación se concrete en la Oficina, en la fecha y hora convenidas.
							<br />
							Para visualizar tus operaciones confirmadas, deberás tocar el área homónima de tu Panel, y podrás ver cada una de ellas como una capsula cerrada, en formato de lista (al igual que las vigentes).
							<br />
							Cada cápsula mostrará en su extremo derecho cuánto resta para concretar la operación en la Oficina.
							<br />
							Si algún operador solicita cambios sobre una operación ya confirmada, dicha eventualidad se indicará en la capsula, con la leyenda “Yo SOLICITÉ CAMBIOS” o “Aliasxxx SOLICITÓ CAMBIOS”.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.12. ¿Cómo se visualizan las operaciones concretadas?
						</Typography>
						<Typography className="section-text">
							Cuando los operadores se presentan en la Oficina y se realiza el intercambio de divisas por pesos, el Administrador marcará a la operación como concretada, indicando en que efectivamente se realizó la transacción.
							<br />
							Para ver tus operaciones concretadas deberás tocar esa área del panel, y podrás ver cada una de las operaciones concretadas como un registro horizontal, en forma de lista.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.13. ¿Qué se incluye en el área “Publicaciones y operaciones
							archivadas”?
						</Typography>
						<Typography className="section-text">
							Se incluye la lista de todas aquellas publicaciones que ya no
							pueden aceptarse, y operaciones que no pudieron concretarse.
							<br />
							Esta área se divide a su vez en dos sub-áreas, donde se clasifican
							los registros de acuerdo al motivo de su archivo, en dos
							categorías:
							<ul>
								<li>Publicaciones vencidas, caducadas y eliminadas.</li>
								<li>Operaciones abortadas.</li>
							</ul>
						</Typography>
						<Typography variant="h5" className="first-section">
							1.14. Cuáles son las denominaciones y versiones de las divisas que se pueden comprar o vender?
						</Typography>
						<Typography className="section-text">
							En Cambiapp sólo se operará exclusivamente con divisas en formato de billetes, no aceptándose monedas metálicas
							<br />
							Para el caso de <strong>DÓLARES</strong>, se admitirán las siguientes denominaciones: Cien, Cincuenta, Veinte, Diez, Cinco y Uno, en las versiones que, para cada denominación, aparezcan en las opciones de selección del sistema. 
							<br />
							Las versiones de billetes se identificarán por la antigüedad de sus series, considerando rangos temporales en los que el diseño billete tuvo cambios importantes. En tal sentido se distinguirán la series:
							<ul>
								<li>
									<strong>“Nueva”</strong> (N): La última serie emitida. Para billetes de cien, se denominará “Azul”
								</li>
								<li>
									<strong>“Anterior”</strong> (A): La penúltima serie emitida.
									Para billetes de cien, se denominará “Cara Grande”
								</li>
								<li>
									<strong>“Vieja”</strong> (V): La serie más antigua aceptada
									por el sistema. Para billetes de cien, se denominará “Cara
									Chica”
								</li>
							</ul>
							El sistema mostrará, para cada denominación, cuál es el rango de
							años de emisión que se considera para clasificar los billetes en
							cada una de las 3 versiones disponibles.
							<br />
							Para las transacciones en EUROS o REALES, se aplicarán todas las denominaciones de billetes vigentes, y no se diferenciarán las versiones. 
							<br />
							Se considera por tanto que cualquier versión de Euros emitida desde su creación en 2002, y cualquier versión de reales emitida desde 1994, resulta indiferente para los operadores de la plataforma.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.15. ¿En qué estado deben estar los billetes de divisa que compro o vendo?
						</Typography>
						<Typography className="section-text">
							Podrás comprar o vender billetes en cualquier estado de
							conservación o condición física, pero deberás indicarlo claramente
							en tu publicación, para ponerlo en conocimiento de tu contraparte.
							<br />
							El sistema habilitará opciones de categorización de los diferentes
							estados de billetes con una numeración de 1 a 4, de acuerdo a la
							condición en que se encuentren, a saber:
							<ol>
								<li>
									<strong>
										<u>Impecables.</u>
									</strong>
								</li>
								<Typography>
									Son los que se encuentren en perfecto estado de conservación,
									sin ningún tipo de mancha, marca, escritura, sello ni
									deterioro.
								</Typography>
								<li>
									<strong>
										<u>Con detalles.</u>
									</strong>
								</li>
								<Typography>
									Son los que en general se encuentran en muy buen estado, pero
									no pueden considerarse impecables ya que poseen alguna sutil
									marca permanente de doblez, o con algún “detalle” de marcas,
									manchas o suciedad, solucionable con tratamientos normales de
									limpieza o desinfección.
									<br />A título enunciativo, se especifican algunas de esas
									características:
									<ul>
										<li>
											Con marca central de doblez, poco pronunciada y apenas
											visible
										</li>
										<li>
											Con leve suciedad y/o manchas de cualquier tipo (grasa,
											pintura, moho, humedad, tierra, etc.).
										</li>
										<li>Apenas rayados y/o escritos con tinta o fibra.</li>
										<li>Con sello en tamaño pequeño, y no más de uno.</li>
									</ul>
								</Typography>
								<li>
									<strong>
										<u>Muy manchados/marcados.</u>
									</strong>
								</li>
								<Typography>
									Con iguales características que el estado 2., pero en mayor
									proporción y gravedad, no pudiendo considerarse que se trata
									de sólo un “detalle” del billete. Pero sus marcas o manchas
									(excepto las de dobles) deben poder ser subsanables con
									tratamientos normales de limpieza o desinfección. Por ejemplo,
									se incluyen en esta categoría los billetes:
									<ul>
										<li>Con marcas de doblez pronunciadas.</li>
										<li>
											Con mucha suciedad y/o con manchas de cualquier tipo
											(grasa, pintura, moho, humedad, tierra, etc.) múltiples o
											generalizadas.
										</li>
										<li>
											Significativamente rayados y/o escritos con tinta o fibra,
											en varios sectores.
										</li>
										<li>
											Con al menos un sello grande y/o múltiples sellos de
											cualquier tamaño.
										</li>
										<li>Levemente ajados por su excesiva manipulación.</li>
									</ul>
								</Typography>
								<li>
									<strong>
										<u>Dañados.</u>
									</strong>
								</li>
								<Typography>
									A diferencia de los estados 1, 2 y 3, se incluirán aquí los
									billetes que, independientemente de sus marcas o manchas,
									presentan daño físico o deterioro permanente e irremediable.
									<br />
									Por ejemplo, se incluyen en esta categoría los billetes:
									<ul>
										<li>Rotos, con partes faltantes.</li>
										<li>Cortados (pegados o no)</li>
										<li>
											Con marcas de doblez centrales muy pronunciadas,
											presentando un retrato “borroneado”.
										</li>
										<li>
											Con quemaduras de cualquier tipo (exposición al calor,
											gomas derretidas, etc.)
										</li>
										<li>
											Con suciedad, moho, manchas o marcas que, por su
											composición química/biológica, antigüedad, penetración y/o
											extensión, sean muy difíciles o imposibles de subsanar,
											sin causar daño físico en el intento.
										</li>
										<li>Muy ajados por su uso en largo tiempo.</li>
									</ul>
									En cualquiera de los casos, para considerarse válido, deberá
									poder identificarse el 60% del billete como mínimo, y
									verificarse íntegramente su numeración.
								</Typography>
							</ol>
						</Typography>
						<Typography variant="h5" className="first-section">
							1.16. ¿En qué estado deben estar los billetes pesos ARS utilizados
							en una transacción?
						</Typography>
						<Typography className="section-text">
							El comprador tiene la obligación de entregar billetes pesos en
							buen estado general, impecables o con detalles, no pudiendo estar
							dañados y/o con marcas/manchas importantes que dificulten su
							posterior circulación.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.17. ¿Qué es cita de transacción, y la lista de citas posibles
							(LCP)?
						</Typography>
						<Typography className="section-text">
							Debe tenerse en cuenta que todas las transacciones se realizan en forma física y personal en la Oficina. Que, a tal fin, a cada operación confirmada se le asigna una cita o “turno”, es decir, una fecha y hora específica para que comprador y vendedor concurran a efectuar la operación de cambio. Y que dicha cita es exclusiva para cada operación, evitando que se superpongan dos operaciones en el mismo día y horario.
							<br />
							Entonces, al cargar tu publicación y, una vez indicadas las condiciones comerciales (precio, cantidad, denominación, estado) de tu oferta o demanda, deberás indicar cuando te gustaría que se realice la transacción en la Oficina, en caso de que tu publicación sea aceptada. Para ello, el sistema te mostrará la lista de turnos disponibles para ser seleccionados, en función de los horarios habituales de funcionamiento de la Oficina. Podrás seleccionar una, varias, o todas las citas disponibles, para que sean incorporadas a tu publicación. Y de esa forma quedará establecida la LCP.
							<br />
							La LCP implica tu manifestación de que aceptarías realizar la operación en la Oficina en cualquiera de los días y horarios que elegiste.
							<br />
							Cuando el otro operador abra tu publicación, podrá ver cuáles son las citas alternativas que seleccionaste y, al aceptarla, elegirá una de ellas de acuerdo a sus posibilidades o preferencias. Entonces, cuantas más citas alternativas incluyas en la LCP de tu publicación, más chances tendrás de que sea aceptada.
							<br />
							La cita elegida por la contraparte al aceptar tu publicación quedará fija e inamovible para realizar la compraventa en la Oficina.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.18. ¿Qué es la anticipación mínima requerida (AMR) y cómo se
							determina?
						</Typography>
						<Typography className="section-text">
							La AMR indica con cuanta anticipación un operador debe aceptar tu
							oferta, antes de que se concrete la operación. Es decir, el tiempo
							mínimo que debe existir entre el momento de confirmación de la
							operación, y la cita de transacción elegida.
							<br />
							Dicha anticipación resulta necesaria teniendo en cuenta que toda operación es efectuada de manera personal en la Oficina, y ello requiere un tiempo de traslado físico y organización de agenda de los operadores. Y tiene especial importancia cuando existen varias citas posibles para efectuar la transacción, ya que la AMR determina el “vencimiento individual” de cada una de esas citas, y determina en definitiva el vencimiento de la publicación.
							<br />
							Al crear tu publicación de venta, en el último paso antes de
							efectuar la publicación, el sistema mostrará de manera automática
							una AMR predeterminada en 30 minutos. Esto significa que, en
							principio, la contraparte deberá aceptar tu publicación con 30
							minutos de anticipación cada cita posible que desee seleccionar.
							Podrás dejar la AMR en dicho valor, o bien ampliarla hasta los
							límites que permita el sistema. Para ello deberás tener en cuenta
							tu ubicación geográfica respecto de la Casa, y tus compromisos de
							agenda.
							<br />
							Por ejemplo, supongamos que seleccionaste las siguientes citas
							posibles: 11:00, 16:00 y 17:00. 
							<br />
							En caso de <strong>dejar los valores
							predeterminados</strong>, cada una de las citas irá venciendo
							automáticamente 30 minutos antes. Entonces, la primera cita
							vencerá a las 10:30, la segunda a las 15:30, y la tercera a las
							16:30. Cuando venza la tercera cita (que es la última posible) es
							cuando se producirá el vencimiento de la publicación completa.
							<br />
							Siguiendo el mismo ejemplo, si decidiste <strong>ampliar la AMR</strong> a 2 horas,
							la primera cita vencerá a las 08:00, la segunda a las 14:00, y la
							tercera a las 15:00.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.19. ¿Cuándo vence mi publicación?
						</Typography>
						<Typography className="section-text">
							La vigencia de una publicación representa el plazo que tienen los
							demás operadores para aceptarla. En caso de no ser aceptada en
							dicho lapso, la publicación quedará vencida, y será archivada, sin
							posibilidad de aceptación posterior.
							<br />
							Se entenderá que la publicación queda vencida cuando sólo quede la
							última cita disponible, y ya no quede tiempo para confirmar la
							operación con la anticipación mínima requerida (AMR). Al crear tu
							publicación, en el último paso antes de subirla, el sistema
							mostrará cuál es el vencimiento predeterminado. Dicho vencimiento
							quedará automáticamente establecido en función de la última cita
							disponible en la LCP, y la AMR definida. Por ejemplo, si al
							momento de crear la publicación es lunes a las 17:00 hs., la
							última cita de la LCP es mañana martes a las 20:00 hs, y la AMR se
							amplió a 2 horas, eso significa que, no habiendo sido aceptada, la
							publicación quedará automáticamente vencida mañana a las 18:00 hs.
							<br />
							Podrás dejar el vencimiento en dicho valor predeterminado, o bien
							podrás adelantarlo, si lo considerás necesario. Siguiendo el mismo
							ejemplo, supongamos que por cuestiones organizativas necesitas que
							tu publicación quede confirmada hoy lunes antes de las 22:00 hs.
							Entonces, antes de levantar la publicación editarás el campo
							vencimiento y lo adelantarás, consignando “lunes 22:00 hs.”. Hecho
							esto, (independientemente de la LCP y de la AMR) no habiendo sido
							aceptada tu publicación hoy mismo, antes de dicho horario, la
							misma quedará vencida.
							<br />
							Todos los operadores podrán ver en tiempo real, la cuenta
							regresiva de hh:mm:ss que muestra cuánto resta para que se agote
							la vigencia de tu publicación.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.20. ¿Cuándo caduca mi publicación?
						</Typography>
						<Typography className="section-text">
							Debe tenerse en cuenta que, mientras tu publicación esté vigente y
							sin novedades, otros operadores podrán confirmar otras operaciones
							en determinadas fechas y horarios. Y puede suceder que alguna de
							esas citas reservadas para otra operación coincida con uno o
							varios de los horarios seleccionados en la LCP de tu propia
							publicación. En tal caso, se le dará prioridad a la selección de
							cita de la publicación ya aceptada, por lo cual se irán reduciendo
							paulatinamente las opciones de citas de la LCP de tu publicación
							todavía vigente. Es decir, las citas que seleccionaste irán
							“desapareciendo” de tu publicación, a medida que otras operaciones
							vayan reservando los mismos turnos.
							<br />
							Si por este motivo se agota la totalidad de citas posibles de tu
							publicación, la misma quedará caducada y será archivada como tal,
							sin posibilidad de ser aceptada.
							<br />
							Por lo expuesto, y para reducir el riesgo de caducidad, te
							recomendamos seleccionar en tu publicación la mayor cantidad de
							citas posibles.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.21. ¿Cuál es la diferencia entre vencimiento y caducidad de mi
							publicación?
						</Typography>
						<Typography className="section-text">
							Tal como se explica en los puntos 1.19 y 1.20, el “vencimiento” se
							refiere al paso del tiempo, y la “caducidad” se refiere a la
							utilización de las citas seleccionadas en otras operaciones de
							cambio.
							<br />
							Entonces se entenderá que cada cita de la LCP, individualmente
							considerada, vence cuando, de acuerdo a la AMR definida, ya no hay
							tiempo para que la contraparte pueda seleccionarla. Y caduca
							cuando el mismo horario fue seleccionado en otra operación ya
							confirmada.
							<br />
							Cuando se agotan las citas disponibles de tu publicación, la misma
							quedará archivada como vencida o como caducada, de acuerdo al
							motivo por el cual la publicación quedó sin efecto, considerando
							exclusivamente la última cita posible:
							<ul>
								<li>
									Si la publicación quedó sin efecto por haber{' '}
									<u>vencido la última cita posible</u>, se archivará como
									VENCIDA
								</li>
								<li>
									Si la publicación quedó sin efecto por haber{' '}
									<u>caducado la última cita posible</u>, se archivará como
									CADUCADA
								</li>
							</ul>
							En conclusión, el vencimiento y la caducidad de una publicación
							tienen exactamente el mismo efecto, que es archivar tu
							publicación, sin posibilidad de ser aceptada.
							<br />
							La única razón por la cual se clasifican en diferentes áreas, es
							para que el emisor sepa cuál fue la causa de que su publicación
							haya quedado sin efecto. Si fue porque nadie quiso aceptar sus
							condiciones durante su vigencia (vencimiento), o fue porque se
							tuvo el infortunio de que otros operadores eligieron los mismos
							turnos en sus propias operaciones (caducidad).
							<br />
							La cápsula de la publicación vigente mostrará en tiempo real la
							cantidad de citas disponibles en la misma, reduciéndose cada vez
							que se produzca un vencimiento o caducidad individual de sus
							citas.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.22. ¿Puedo pausar mi publicación?
						</Typography>
						<Typography className="section-text">
							Sí. En cualquier momento antes de su aceptación, podrás pausar tu
							publicación de venta, dando clic en el botón “PAUSAR/MODIFICAR".
							<br />
							Esto podría ser útil, por ejemplo, si observas que existe una
							publicación de compra de otro operador que se corresponde con la
							cantidad/calidad de tu publicación de venta propia, y considerás
							aceptarla. Entonces, antes aceptar la publicación de la
							contraparte, pausarás la propia para evitar que en ese mismo
							instante otro operador la acepte a la tuya, y quedes doblemente
							comprometido por un mismo lote, dificultándose el cumplimiento.
							<br />
							Cuando se pausa una publicación, esta dejará de ser visible para
							el resto de los operadores, y en tu panel de ventas se mostrará
							con la indicación de que se encuentra pausada. No obstante,
							seguirá corriendo normalmente la cuenta regresiva correspondiente
							a su vencimiento, y actualizándose el consumo de citas.
							<br />
							Cuando lo desees, podrás dar clic en el botón “ACTIVAR", para que sea nuevamente vista por el resto de los operadores.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.23. ¿Puedo negociar la publicación de otro operador?
						</Typography>
						<Typography className="section-text">
							Cambiapp no admite a los operadores entablar entre sí
							negociaciones directas bajo la modalidad de pujas o
							contrapropuestas, sino que sólo podrán aceptar íntegramente las
							condiciones (cantidad, calidad, precio, cita) de una publicación,
							o no hacerlo.
							<br />
							Pero sí podés negociar de manera “indirecta”, efectuando una
							publicación propia que sea similar o compatible con la publicación
							de otro que querés negociar.
							<br />
							Por ejemplo: Tigre32 creó una publicación de venta de USD 1.500
							azules impecables a un precio de $ 1.000. Y vos estas dispuesto a
							comprarle esa misma cantidad y calidad de dólares, pero a un
							precio de $ 995. Entonces, tu forma de negociarle a ese operador
							es generando una publicación de compra a de USD 1.500 azules
							impecables a $ 995. Tu publicación será vista por Tigre32, y de
							esa manera lo estarás invitando a que sea él el que acepte tu
							publicación, en caso de que esté dispuesto a venderte a menor
							precio.
							<br />
							Debes tener en cuenta que, si nadie acepta tu publicación durante
							su vigencia, es posible que tus condiciones no resulten atractivas
							para el mercado. En tal caso, podrás probar modificando el precio,
							o efectuando nuevas publicaciones por diferente precio y/o
							cantidad.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.24. ¿Se puede comprar o vender por una cantidad menor a la
							publicada?
						</Typography>
						<Typography className="section-text">
							Sí, siempre y cuando el emisor de la publicación lo permita. Para
							ello, cuando se carga una publicación de venta o de compra, debe
							activarse la opción “Permitir aceptación parcial”. En tal caso,
							también se deberá indicar cual es la cantidad parcial mínima que
							se puede aceptar. Dicha cantidad debe ser igual o mayor a USD 100
							y múltiplo de la denominación de los billetes que se está
							ofreciendo o demandando.
							<br />
							Por ejemplo, si creaste una publicación de venta por un total de
							USD 500, en billetes de cien, podrás habilitar la posibilidad de
							que los otros operadores te compren una cantidad menor. Y cuando
							te pregunte cual es la cantidad parcial mínima permitida podrás
							consignar: 100, 200, 300 o 400. Es decir, cualquier cantidad que
							sea mayor a 100 y múltiplo de 100. Si, por ejemplo, consignás que
							la cantidad mínima es 150, el sistema arrojará error, por no ser
							múltiplo de 100. Una vez efectuada una aceptación parcial, la
							cantidad de la publicación se modificará automáticamente, quedando
							actualizada por la cantidad restante. Siguiendo el ejemplo, si la
							publicación de venta se hizo por un total de USD 500, y un
							operador aceptó comprar USD 200, la publicación quedará
							actualizada y vigente por una cantidad de USD 300.
							<br />
							Si la cantidad restante es igual o menor a la cantidad parcial
							mínima permitida, la publicación quedará vigente por dicha
							cantidad restante, pero ya sin permitir aceptación parcial. Es
							decir, como una publicación común.
							<br />
							Debes tener en cuenta que con cada aceptación parcial se van
							consumiendo tus citas disponibles. Y que una vez consumidas todas
							las citas, aun cuando quede cierto stock por vender o comprar, tu
							publicación se dará por caducada y desaparecerá del panel de
							publicaciones vigentes. Por tal razón, si tu publicación admite
							compra parcial, deberás tener el recaudo de habilitar una buena
							cantidad de citas posibles.
							<br />
							Las capsulas (cerradas o abiertas) de publicaciones que permitan
							aceptación parcial mostrarán la cantidad y el importe total en
							tipografía de color amarillo, para distinguirlas de las
							publicaciones comunes, que se seguirán mostrando con números
							blancos. Y, al abrir la capsula, podrá verse cual es la cantidad
							parcial mínima que se puede aceptar.
							<br />
							La opción de aceptación parcial sólo estará habilitada para
							publicaciones que contengan un solo lote. Si se trata de una
							publicación de venta que contiene dos lotes diferentes, sólo podrá
							comprarse la cantidad total.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.25. ¿Puedo modificar mi publicación?
						</Typography>
						<Typography className="section-text">
							Durante la vigencia de tu publicación, podrás modificar tres condiciones: el precio, 
							la cantidad y citas posibles.
							<br />
							Para ello, deberás tocar el botón “PAUSAR/MODIFICAR”. 
							Y una vez que la publicación se encuentre pausada, tocarás el botón “MODIFICAR”.
							<br />
							En dicho punto, aparecerán los botones correspondientes las tres condiciones que se pueden modificar: “CAMBIAR PRECIO”, “CAMBIAR CANTIDAD” y “CAMBIAR CITAS”.
							<br />
							Si querés modificar otras condiciones tales como denominación, versión o estado de las divisas, denominación mínima de billetes ARS, o adelantar el vencimiento, deberás eliminar la publicación, para luego cargar una nueva publicación con las nuevas condiciones deseadas.
							<br />
							En relación a la cantidad de la publicación, si la misma contiene un solo lote sólo podrás reducir o aumentar la cantidad de dicho lote. Y si contiene dos lotes, podrás modificar la cantidad de cada uno de ellos, o bien eliminar un lote completo.
							<br />
							La nueva cantidad deberá ajustarse a la denominación de los billetes USD que se están ofreciendo o demandando.
						</Typography>
						<Typography variant="h5" className="first-section">
							1.26. ¿Puedo eliminar mi publicación?
						</Typography>
						<Typography className="section-text">
							Sí. La publicación puede eliminarse en cualquier momento mientras
							aún se encuentre vigente, sin que ello se considere un
							incumplimiento.
						</Typography>
					</Box>
					<Typography variant="h4" className="second-section section-header">
						2. Cómo vender
					</Typography>
					<Box className="section-container">
						<Typography variant="h5" className="second-section">
							2.1. ¿Cómo hago para vender divisas?
						</Typography>
						<Typography className="section-text">
							En la pantalla de inicio, tocá “VENDER DIVISAS”, y vas a ingresar a tu Panel de Ventas.
							<br />
							Allí deberás indicar cuál es la divisa que queres comprar, utilizando el botón de selección que aparece en el encabezado.
							<br />
							Y luego podrás vender de dos maneras diferentes:
							<ul>
								<li>
									<strong>Vía directa</strong>: Creando una
									publicación de venta propia, que luego debe ser aceptada por
									un comprador.
								</li>
								<li>
									<strong>Vía inversa</strong>: Aceptando la publicación de
									compra hecha por otro operador.
								</li>
							</ul>
						</Typography>
						<Typography variant="h5" className="second-section">
							2.2. ¿Cómo creo mi publicación de venta?
						</Typography>
						<Typography className="section-text">
							En tu Panel de Ventas, deberás tocar “NUEVA PUBLICACIÓN DE VENTA”,
							 y se abrirá el formulario de carga, donde deberás indicar
							cantidad, denominación, versión, estado, precio, y citas de
							transacción.
							<br />
							Una vez editada y publicada, tu oferta podrá visualizarse como una
							nueva cápsula en el área Publicaciones Vigentes de tu Panel de
							Ventas. Y también se verá en los Paneles de Compras del resto de
							los operadores.
						</Typography>
						<Typography variant="h5" className="second-section">
							2.3. ¿Qué cantidad puedo vender?
						</Typography>
						<Typography className="section-text">
							La cantidad mínima a ofrecer en tu publicación para dólares, euros y reales, 
							es de USD 100, EUR 100, y BRL 500, respectivamente. Y la cantidad la máxima quedará 
							limitada por los dígitos que permita ingresar el correspondiente campo del sistema. 
							<br />
							Al cargar tu publicación podrás habilitar la opción “Permitir aceptación parcial”, 
							posibilitando a los demás operadores adquirir una cantidad menor a la total publicada, 
							de acuerdo a lo establecido en el punto 1.24. 
						</Typography>
						<Typography variant="h5" className="second-section">
							2.4. ¿Puedo incluir en una sola publicación de venta, billetes de
							diferente denominación, versión y estado?
						</Typography>
						<Typography className="section-text">
							Sí. Cada publicación podrá contener hasta 2 lotes con diferentes
							combinaciones de denominación/versión/estado de billetes, debiendo
							indicar para cada lote la cantidad que corresponda. Por ejemplo,
							una publicación de USD 1.300 en total puede contener 2 lotes: un
							lote de USD 1.000, en billetes de cien, versión nueva, impecables,
							y otro lote de USD 300, en billetes de cincuenta, versión
							anterior, con detalles.
							<br />A fines comerciales, te recomendamos que tu publicación sea
							lo más homogénea posible, de tal forma de que, si incluís varios
							lotes, estos no contengan billetes de denominación, versión o
							estado muy diferentes entre sí. Por ej., no se recomienda que una
							misma publicación incluya billetes de Cien y de Uno. O billetes
							nuevos y viejos. O billetes impecables y muy marcados/manchados.
							En caso de que desees vender billetes de características muy disimiles, 
							es preferible que efectúes varias publicaciones distintas. 
						</Typography>
						<Typography variant="h5" className="second-section">
							2.5. ¿Al concretarse la venta, puedo entregar al comprador
							billetes en mejores condiciones que las especificadas en mi
							publicación?
						</Typography>
						<Typography className="section-text">
							Sí. Las especificaciones de denominación, versión y estado que
							indicaste en cada lote de tu publicación se consideran “mínimas”.
							Esto significa que, al concretarse la operación, se dará por
							entendido que el comprador acepta recibir billetes en iguales o en
							mejores condiciones que las publicadas, en cualquier proporción.
							Se considera que los billetes están en mejores condiciones cuando
							son de mayor denominación, versión más nueva, o en mejor estado
							que lo especificado en la publicación.
							<br />
							Por ejemplo, si en el lote publicado indicaste que la cantidad de
							dólares ofrecida se entregará en billetes de cincuenta, versión
							anterior y con detalles, al concretar la operación podrás entregar
							total o parcialmente billetes de cien, versión nueva, e
							impecables, en cualquier proporción.
							<br />
							La única excepción a esta regla se da cuando, para completar la
							cantidad total de la publicación, sea necesario incluir billetes
							dólares de menor denominación. Pero teniendo en cuenta de que
							tales billetes sean lo más “grandes” posibles. Por ejemplo, si tu
							publicación contiene una cantidad de USD 1.525, podrás consignar
							que la denominación mínima de tus dólares es de Cien. No obstante,
							se considerará igualmente cumplida la condición si, para completar
							dicha cantidad total, le entregas al comprador quince billetes de
							cien, un billete de veinte y otro de cinco. Pero no podrás
							completar la fracción de USD 25 con billetes de un dólar, ya que
							los de menor denominación deben ser los más grandes posibles.
							Además, esos billetes de menor denominación deben ser de versión
							igual o más nueva que la especificada en la publicación, y
							encontrarse en igual o mejor estado.
						</Typography>
						<Typography variant="h5" className="second-section">
							2.6. ¿A qué precio puedo vender mis divisas?
						</Typography>
						<Typography className="section-text">
							El precio es un dato obligatorio en tu publicación de venta.
							Deberás consignar el precio unitario en pesos, y el sistema
							calculará automáticamente el precio total de la publicación, en
							función de la cantidad ofrecida.
							<br />
							Podrás ofrecer tus divisas a cualquier precio que desees, ya que
							Cambiapp no prevé condiciones en cuanto al precio mínimo o máximo
							de tu oferta. Sin perjuicio de ello, se te recomienda tomar como
							referencia las cotizaciones del tipo de cambio libre o “blue”,
							publicadas en portales o diarios digitales reconocidos, y
							considerar especialmente la versión y estado de los billetes que
							ofreces, ya que dichas condiciones son habitualmente tenidas en
							cuenta en el mercado, a la hora de concertar operaciones.
							<br />
							Recordá que se consigna un sólo precio unitario para toda la
							publicación. Si la publicación contiene 2 lotes, se entenderá que
							el precio indicado corresponde al promedio de todos los billetes
							incluidos en la publicación, sin discriminación alguna por cada
							denominación, versión o estado. Por ejemplo: En tu publicación
							ofrecés un lote de USD 500 en billetes de cien, serie nueva,
							impecables, que estás dispuesto a vender a un precio unitario de
							$1.000. Y otro lote de USD 500 en billetes de diez, serie vieja,
							muy marcados/manchados, que estás dispuesto a vender a $950.
							Entonces, se te recomienda calcular (por afuera del sistema) el
							promedio, y consignar para todo el lote un único precio unitario
							de $975.
						</Typography>
						<Typography variant="h5" className="second-section">
							2.7. ¿Puedo requerir que, en caso de concretarse la venta, el
							comprador me entregue billetes pesos “grandes”?
						</Typography>
						<Typography className="section-text">
							Sí. Cuando crees tu publicación de venta, el sistema te requerirá
							que indiques cuál es la denominación mínima de los billetes pesos
							que deseas recibir del comprador, y quienes acepten tu oferta
							deberán cumplir con esa condición. Por ejemplo, si consignás “Dos
							mil”, significará que el comprador sólo podrá pagarte con billetes
							pesos de dos mil. Pero si pusiste “Quinientos”, significa que se
							te podrá pagar indistintamente con billetes de quinientos, mil, dos mil o diez mil,
							 en cualquier proporción.
							<br />
							Sólo se aceptarán billetes pesos de menor denominación que la
							indicada, en caso de que éstos se requieran para integrar la
							fracción que permite completar la el precio total. Por ejemplo, si
							se compran USD 1.200 a $1.094 = $1.312.800. Entonces, si el
							vendedor consignó que sólo recibirá billetes de mil, se le
							aceptará al comprador que complete los $800 de su compra con
							billetes de denominación menor, pero considerando los billetes más
							grandes posibles. En este caso se completaría con un billete de
							quinientos, uno de doscientos y uno de cien.
						</Typography>
						<Typography variant="h5" className="second-section">
							2.8. ¿Cómo hago para vender por vía inversa?
						</Typography>
						<Typography className="section-text">
							La vía inversa consiste en aceptar la publicación de compra de
							otro operador.
							<br />
							Tu panel de ventas te mostrará en el área de Publicaciones
							Vigentes, no sólo tus propias publicaciones de venta (si las
							hubiere), sino también las publicaciones de compra hechas por
							otros operadores (compradores). Si alguna de esas publicaciones de
							compra es de tu interés, podes seleccionarla y dar clic en VENDER.
							Habiendo ingresado a la pantalla de aceptación, seleccionarás la
							cita de transacción. Y por último confirmarás tu venta (o
							aceptación de su publicación de compra) quedando el acuerdo firme
							para ambos operadores.
							<br />
							Si la publicación lo permite, podrás vender sólo una parte de las divisas 
							demandadas por la contraparte, tocando el botón “VENDER MENOR CANTIDAD”.
						</Typography>
					</Box>
					<Typography variant="h4" className="third-section section-header">
						3. Cómo comprar
					</Typography>
					<Box className="section-container">
						<Typography variant="h5" className="third-section">
							3.1. ¿Cómo hago para comprar divisas?
						</Typography>
						<Typography className="section-text">
							En la pantalla de inicio, tocá “COMPRAR DIVISAS”, y vas a ingresar a tu Panel de Compras.
							<br />
							Allí deberás indicar cuál es la divisa que queres comprar, utilizando el botón de selección que aparece en el encabezado.
							<br />
							Y luego podrás comprar de dos maneras diferentes:
							<ul>
								<li>
									<strong>Vía directa</strong>: Creando una
									publicación de compra propia, que luego debe ser aceptada por
									un vendedor.
								</li>
								<li>
									<strong>Vía inversa</strong>: Aceptando la publicación de
									venta hecha por otro operador.
								</li>
							</ul>
						</Typography>
						<Typography variant="h5" className="third-section">
							3.2. ¿Cómo creo mi publicación de compra?
						</Typography>
						<Typography className="section-text">
							En tu Panel de Compras, deberás tocar "NUEVA PUBLICACIÓN DE
							COMPRA", y se abrirá el formulario de carga, donde deberás indicar
							cantidad, denominación, versión, estado, precio, y citas posibles
							de transacción.
							<br />
							Una vez emitida y subida la publicación, tu demanda podrá
							visualizarse en el área Publicaciones Vigentes de tu Panel de
							Compras, como así también en los Paneles de Ventas del resto de
							los operadores (vendedores).
						</Typography>
						<Typography variant="h5" className="third-section">
							3.3. ¿Qué cantidad puedo demandar en mi publicación de compra?
						</Typography>
						<Typography className="section-text">
							La cantidad mínima a demandar en tu publicación para dólares, euros y reales, 
							es de USD 100, EUR 100, y BRL 500, respectivamente. Y la cantidad la máxima quedará 
							limitada por los dígitos que permita ingresar el correspondiente campo del sistema.
							<br />
							Al cargar tu publicación podrás habilitar la opción “Permitir aceptación parcial”, 
							posibilitando a los demás operadores venderte una cantidad menor a la total publicada, 
							de acuerdo a lo establecido en el punto 1.24. 
						</Typography>
						<Typography variant="h5" className="third-section">
							3.4. ¿Puedo incluir en mi publicación de compra, billetes de
							diferente denominación, versión y estado?
						</Typography>
						<Typography className="section-text">
							No. La publicación de compra sólo podrá contener un sólo lote de
							determinada cantidad, denominación, versión y estado.
						</Typography>
						<Typography variant="h5" className="third-section">
							3.5. ¿Al concretarse la compra, puedo recibir del vendedor
							billetes en mejores condiciones que las especificadas en mi
							publicación?
						</Typography>
						<Typography className="section-text">
							Sí. Las especificaciones de denominación, versión y estado que
							indicaste en tu publicación se consideran “mínimas”. Esto
							significa que, al concretarse la operación, se dará por entendido
							que aceptás recibir billetes en iguales o en mejores condiciones
							que las publicadas, en cualquier proporción. Se considera que los
							billetes están en mejores condiciones cuando son de mayor
							denominación, de versión más nueva, o están en mejor estado que lo
							especificado en la publicación.
							<br />
							Por ejemplo, si en el lote publicado indicaste que la cantidad de
							dólares demandada te sea entregada en billetes de cincuenta,
							versión anterior, y con detalles, al concretar la operación
							aceptarás igualmente que el vendedor te entregue total o
							parcialmente billetes de cien, versión nueva, e impecables, en
							cualquier proporción.
						</Typography>
						<Typography variant="h5" className="third-section">
							3.6. ¿A qué precio puedo comprar?
						</Typography>
						<Typography className="section-text">
							El precio es un dato obligatorio. Deberás consignar el precio
							unitario en pesos, y el sistema calculará automáticamente el
							precio total de la publicación, en función de la cantidad
							demandada.
							<br />
							Podrás comprar a cualquier precio que desees, ya que Cambiapp no
							prevé condiciones en cuanto al precio mínimo o máximo de tu
							demanda. Sin perjuicio de ello, se te recomienda tomar como
							referencia las cotizaciones al tipo de cambio libre o “blue”
							publicadas en portales o diarios digitales reconocidos, y
							considerar especialmente la versión y estado de los billetes que
							demandás, ya que dichas condiciones son habitualmente tenidas en
							cuenta en el mercado, a la hora de concertar operaciones.
						</Typography>
						<Typography variant="h5" className="third-section">
							3.7. ¿Debo indicar en mi publicación con qué denominación de
							billetes pesos cancelaré la compra, en caso de concretarse?
						</Typography>
						<Typography className="section-text">
							Sí, cuando crees tú publicación de compra, el sistema te requerirá
							que indiques cuál es la denominación mínima de los billetes pesos
							con los que cancelarás tu operación, en caso de concretarse. Por
							ejemplo, si consignás “Dos mil”, significará que sólo podrás
							pagarle con billetes pesos de dos mil. Pero si pusiste
							“Quinientos”, significa que podrás pagar indistintamente con
							billetes de quinientos, de mil, de dos mil o de diez mil, en cualquier
							proporción. Sólo se aceptará que abones con billetes pesos de
							menor denominación que la indicada, en caso de que éstos se
							requieran para completar la compra del lote. Por ejemplo, si se
							compran USD 1.200 a $997 = $1.196.400. Entonces, aunque hayas
							indicado “Mil” en la denominación mínima de tu publicación, se te
							aceptará que completes los $400 de tu compra con billetes de
							denominación menor, pero considerando los billetes más grandes
							posibles. En este caso se completaría con dos billetes de
							doscientos.
						</Typography>
						<Typography variant="h5" className="third-section">
							3.8. ¿Cómo hago para comprar por vía inversa?
						</Typography>
						<Typography className="section-text">
							La vía inversa consiste en aceptar la publicación de un vendedor.
							<br />
							Tu panel de compras te mostrará en el área de Publicaciones
							Vigentes, no sólo tus propias publicaciones de compra (si las
							hubiere), sino también las publicaciones de venta hechas por otros
							operadores (vendedores). Si alguna de esas publicaciones de venta
							es de tu interés, podes dar clic en "COMPRAR", y fijarás la cita de
							transacción. En ese instante estas aceptando la oferta, y la
							operación queda automáticamente confirmada para ambos.
							<br />
							Si la publicación lo permite, podrás comprar sólo una parte de 
							las divisas ofrecidas por la contraparte, tocando el botón “COMPRAR MENOR CANTIDAD”.
						</Typography>
					</Box>
					<Typography variant="h4" className="fourth-section section-header">
						4. Incumplimientos. Responsabilidades. Reputación.
					</Typography>
					<Box className="section-container">
						<Typography variant="h5" className="fourth-section">
							4.1. ¿Quién se hace responsable por el incumplimiento de una
							operación confirmada?
						</Typography>
						<Typography className="section-text">
							La responsabilidad de cumplir en tiempo y forma con las
							operaciones confirmadas en la plataforma, es exclusiva de los
							operadores que cerraron el acuerdo, y no de la Casa de Cambios.
							<br />
							La responsabilidad del Administrador se limita a garantizar el
							buen funcionamiento de la plataforma on-line, brindar a los
							usuarios (operadores) un espacio físico para realizar la operación
							de cambio, y realizar el control de autenticidad e integridad de
							los billetes negociados, de acuerdo a las condiciones pactadas.
							<br />
							Asimismo, en caso de verificarse un incumplimiento, será el
							Administrador quien intervenga para informar a la contraparte (si
							fuera necesario), intermediar entre los operadores para tratar de
							renegociar el acuerdo, y juzgar el incumplimiento, su
							justificación y su gravedad. En tal sentido, podrá informar los
							datos personales del usuario incumplidor, y hasta expulsarlo de la
							comunidad, cuando corresponda.
							<br />
							Por lo tanto, el operador asume el riesgo de que se produzca un
							incumplimiento, no pudiendo reclamar al Administrador compensación
							alguna, ni tampoco hacerlo responsable por la eventual falta de
							compensación del usuario incumplidor.
							<br />
							En relación a la calidad de los billetes y/o divisas que los usuarios reciben de la contraparte, 
							el Administrador brindará asesoramiento sobre su autenticidad y estado. Ello no quita la responsabilidad 
							de cada usuario de controlar por sí mismo dichas condiciones, y dar conformidad al momento de concretarse 
							la operación, no pudiendo efectuar ningún reclamo con posterioridad al momento de haberse retirado de la Oficina. 
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.2. ¿Qué debo hacer si no puedo cumplir con una operación
							confirmada?
						</Typography>
						<Typography className="section-text">
							Si por algún motivo se te dificulta o impide cumplir en tiempo y
							forma con la operación confirmada, en cualquiera de sus
							condiciones (cantidad, denominación, versión, estado, hora de
							transacción, precio, etc.), tenés la obligación de:
							<ul>
								<li>
									Informar dicha situación mediante el sistema, en lo posible al
									menos 30 minutos antes de la cita. Para ello tocarás el botón
									"SOLICITAR CAMBIOS", si requerís que la operación se realice con
									cambios. O bien el botón "ABORTAR", si directamente se te hace
									imposible concertar la operación. Si te resulta imposible
									realizar dicha gestión en el sistema, deberás informar
									inmediatamente al Administrador personalmente, por teléfono, o por WhatsApp. 
								</li>
								<li>
									Justificar el incumplimiento, informando claramente las causas
									al Administrador.
								</li>
								<li>
									De ser posible, renegociar o reprogramar cita con la
									contraparte por afuera del sistema (teléfono, WhatsApp), con
									mediación del Administrador, para intentar que la operación se
									realice igualmente, compensándolo por el perjuicio causado.{' '}
								</li>
							</ul>
							Recordá que el incumplimiento quedará registrado en tu reputación,
							que podrían informarse tus datos personales a la contraparte, y
							que podrías ser expulsado de Cambiapp.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.3. ¿Puedo solicitar cambios sobre una operación que ya confirmé?
						</Typography>
						<Typography className="section-text">
							Sí. Excepcionalmente y cuando la situación lo amerite, podrás
							solicitar cambios en una operación ya confirmada, ya sea en las
							condiciones comerciales, como en la cita de transacción, con la
							intención de concretar igualmente la operación, mediante un nuevo
							acuerdo.
							<br />
							Para ello el deberás dar clic en el botón "SOLICITAR CAMBIOS". Hecho esto, la capsula de 
							operación confirmada se verá con la leyenda indicativa de la solicitud de cambios, tanto 
							en tu panel, como en el panel de la contraparte de la operación, y con indicación de la 
							fecha y hora en que se pidió el cambio. 
							<br />
							Mientras la cápsula esté en el nuevo estado “Solicitó cambios”,
							ninguna de las partes va a poder tomar decisiones respecto de esa
							operación en el Sistema, quedando la publicación en “stand by”,
							hasta definir cómo se resuelve.
							<br />
							Luego de dar aviso en el Sistema, deberás contactarte
							inmediatamente con el Administrador vía teléfono o WhatsApp, para
							dar detalles de los cambios solicitados, y que éste se comunique
							con la contraparte, intermediando en la re-negociación. Por lo
							tanto, en el sistema no se precisan los cambios solicitados, sino
							que solamente se da aviso de la solicitud. Y tales detalles se
							comunican directamente al Administrador.
							<br />
							Si te resulta imposible registrar la solicitud de cambios en el
							Sistema, deberás comunicarlo directamente al Administrador por
							otro medio (teléfono o WhatsApp), entonces éste último será el que
							marque la solicitud de cambios en el Sistema, para que la capsula
							cambie de estado.
							<br />
							Tomada nota de la solicitud, el Administrador se comunicará con la
							contraparte y tratará de acordar nuevamente por afuera del
							sistema, vía teléfono o WhatsApp, para que la operación se realice
							igualmente, aunque sea con cambios.
							<br />
							De llegarse a un acuerdo, el Administrador insertará los cambios
							acordados, y editará manualmente las nuevas condiciones en el
							registro de la operación. Entonces, la capsula quedará normalmente
							confirmada con las nuevas condiciones, pudiendo ambas partes
							visualizar las nuevas condiciones para su cumplimiento efectivo.
							<br />
							Recordá que, aun cuando realices el procedimiento recomendado,
							solicitar cambios configura un incumplimiento. Y que tal
							antecedente quedará registrado en tu historial y reputación,
							pudiendo incluso dar lugar a las sanciones, incluyendo tu
							expulsión de Cambiapp en el caso que corresponda.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.4. ¿Qué pasa si solicito cambios en una operación confirmada, y
							mi contraparte no los acepta?
						</Typography>
						<Typography className="section-text">
							Si no se llega a un acuerdo sobre los cambios solicitados, el
							Administrador dará por Abortada la operación por tu parte,
							quedando la misma archivada.
							<br />
							En otras palabras, para Cambiapp solicitar cambios sobre una
							operación confirmada, que luego no son aceptados, es exactamente
							lo mismo que abortarla.
							<br />
							Recordá que esto configura un incumplimiento grave que quedará
							marcado en la reputación. Y puede dar lugar a la suspensión, la
							exhibición de datos personales, y hasta a la expulsión del
							operador en falta.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.5. ¿Puedo abortar una operación confirmada?
						</Typography>
						<Typography className="section-text">
							Sí. El aborto deja sin efecto la operación confirmada, sin
							posibilidad de negociación, quedando la misma automáticamente
							archivada.
							<br />
							Para hacerlo, deberá seleccionar el botón "ABORTAR" en tu capsula
							abierta.
							<br />
							Recordá que esto configura un incumplimiento grave que quedará
							marcado en la reputación. Y puede dar lugar a la suspensión, la
							exhibición de datos personales, y hasta tu expulsión de la
							comunidad.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.6. ¿Qué pasa si surge un desacuerdo imprevisto al momento de
							concretar una operación?
						</Typography>
						<Typography className="section-text">
							Puede darse la situación de que ninguna de las partes solicite
							cambios, pero que llegado el momento de concretar la operación en
							la Casa, se produzca un desacuerdo que ponga en riesgo el negocio.
							Por ejemplo, se acordó la compraventa de USD 1.000 versión nueva,
							con detalles, y el comprador considera que hay billetes que no
							están levemente manchados, sino muy manchados. Entonces, el
							Administrador intermediará para que se realice igualmente la
							operación. Siguiendo el mismo ejemplo, podría suceder que:
							<ol type="a">
								<li>
									Que la operación se concrete en las mismas condiciones
									confirmadas. En este caso será el Administrador quien decida
									si el vendedor cumplió o no con el estado de los billetes. Y,
									en caso negativo, registrará la solicitud de cambios aceptada.
								</li>
								<li>
									Que la operación se realice, pero con diferentes condiciones,
									ya que el comprador exigió una compensación (por ej., menor
									precio). En tal caso, el Administrador simplemente utilizará
									la opción MODIFICAR REGISTRO, modificándose las condiciones en
									todos los paneles.
								</li>
								<li>
									Que no se concrete la operación, por no llegarse a un acuerdo.
									En este caso el Administrador juzgará quién es realmente el
									operador incumplidor, y registrará la operación como ABORTADA
									por quien corresponda.
								</li>
							</ol>
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.7. ¿Qué sanciones se aplican por incumplimiento?
						</Typography>
						<Typography className="section-text">
							Se considera <b>incumplimiento</b>, al hecho de que un operador no
							cumpla en tiempo (fecha y hora acordada) y/o forma (precio,
							cantidad, denominación, versión, estado) con las condiciones
							acordadas de una operación confirmada.
							<br />
							Dado el caso, el Administrador tomará las siguientes medidas sobre
							el operador incumplidor:
							<ol type="a">
								<li>
									Lo <b>suspenderá</b> transitoriamente hasta verificar la
									justificación. Durante la suspensión no podrá publicar,
									aceptar publicaciones, ni recibir aceptaciones de otros
									operadores.
								</li>
								<li>
									Podrá revelar a la contraparte su <b>identidad real</b> y el
									contacto telefónico.
								</li>
								<li>
									Computará el incumplimiento en su <b>reputación</b>, indicando
									si fue leve o grave.
								</li>
								<li>
									Si el cumplimiento es <u>injustificado</u>, el operador será{' '}
									<b>expulsado</b> del sistema, aun cuando la operación se
									realice igualmente.
								</li>
							</ol>
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.8. ¿Cuándo un incumplimiento se considera justificado, y cuándo
							injustificado?
						</Typography>
						<Typography className="section-text">
							Se entenderá que el incumplimiento de un operador está “
							<b>justificado</b>”, cuando informe al Administrador sobre las
							causas de su incumplimiento, y estas resulten razonables y
							creíbles a criterio de éste último, pudiendo a efectos de tal
							determinación solicitarle pruebas de los hechos o circunstancias
							que se alegan.
							<br />
							Por ejemplo: Inclemencias climáticas, restricciones a la
							circulación, enfermedad, accidente, u otros hechos fortuitos o
							causas de fuerza mayor.
							<br />
							En caso de no darse tales presupuestos, se considerará que el
							incumplimiento fue “<b>injustificado</b>”.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.9. ¿Cuándo un incumplimiento se considera grave, y cuándo leve?
						</Typography>
						<Typography className="section-text">
							Se considerará que el incumplimiento de una operación confirmada
							es
							<b>grave</b> cuando:
							<br />
							a) <u>No se concreta</u> a causa del incumplimiento. Ni en las
							condiciones acordadas, ni en nuevas condiciones renegociadas.
							<br />
							b) Habiendo sido igualmente <u>concretada</u> con cambios
							renegociados, se verifican situaciones que se consideran muy
							perjudiciales para la contraparte cumplidora, sin existir una
							adecuada compensación por parte del incumplidor. Por ejemplo:
							<ul>
								<li>
									El operador no informó su imposibilidad de cumplir el acuerdo
									con antelación mínima de 30 minutos a la cita de transacción,
									ya sea mediante el Sistema o por cualquier otro medio
									(teléfono, WhatsApp).
								</li>
								<li>
									Se requirió un cambio en el precio pactado (sin importar en
									cuánto).
								</li>
								<li>
									La operación debió realizarse con cambios significativos en
									las condiciones acordadas, en perjuicio del operador
									cumplidor.
									<ul>
										<li>Variación de la cantidad en un 20% o superior. </li>
										<li>
											Billetes dólares en estado mucho peor al acordado
											(divisas) o aceptable (pesos).
										</li>
										<li>
											Billetes pesos o dólares de denominación muy inferior a la
											mínima acordada.
										</li>
										<li>Modificación en la cita de 4 horas o superior</li>
									</ul>
								</li>
							</ul>
							Se considerará que el incumplimiento de una operación confirmada
							es leve cuando la operación se concreta igualmente, y no se da
							ninguno de los supuestos especificados en el inciso b).
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.10. ¿Puedo calificar a los otros operadores?
						</Typography>
						<Typography className="section-text">
							No. En Cambiapp no existirá la calificación emitida por los
							operadores, sino que sólo será el Administrador quien juzgará el
							accionar de los mismos en relación a su nivel de cumplimiento de
							operaciones confirmadas.
							<br />
							Además, el sistema registrará y computará automáticamente los
							antecedentes operativos de cada operador, para formar su
							reputación en Cambiapp.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.11. ¿Cómo se determina la reputación de un operador?
						</Typography>
						<Typography className="section-text">
							El sistema llevará estadísticas de casi todas las acciones de los
							usuarios en el sistema, tales como publicaciones, confirmaciones,
							concreciones y cancelaciones, discriminándose entre compras y
							ventas.
							<br />A partir de esas estadísticas, se irá formando la reputación
							del operador, la cual comprenderá 4 aspectos:
							<ul>
								<li>Antigüedad en la aplicación.</li>
								<li>Cantidad de operaciones concretadas.</li>
								<li>Incumplimientos leves y graves.</li>
								<li>Estatus.</li>
							</ul>
							La reputación de un operador podrá ser vista en sus publicaciones
							vigentes, antes de ser aceptadas por otro usuario.
						</Typography>
						<Typography variant="h5" className="fourth-section">
							4.12. ¿Qué significa el sello “operación garantizada”?
						</Typography>
						<Typography className="section-text">
							El operador que realice una publicación de venta podrá, antes de
							que ésta sea aceptada, dejar los billetes ofrecidos depositados en
							la Casa, en poder del Administrador.
							<br />
							El Administrador controlará la integridad y estado de las divisas,
							y conservará dicho lote reservándolo para a la correspondiente
							venta, en caso de que la publicación sea aceptada.
							<br />
							El mismo procedimiento se aplicará para las publicaciones de
							compra cuando, a los efectos de garantizar la operación, el
							comprador deposita en la Oficina el efectivo (pesos) necesarios para
							concretar la transacción en las condiciones publicadas.
							<br />
							En ambos casos, la publicación mostrará el sello “Garantizada”
							insertado por el Administrador, lo cual aumentará las
							probabilidades de confirmación de la contraparte.
							<br />
							Si la operación es confirmada, no será necesario que el operador
							que dejó su parte depositada asista puntualmente a la cita
							inicialmente acordada, pudiendo convenir con el Administrador una
							reunión posterior, para retirar el efectivo o divisas resultantes
							de dicha operación.
							<br />
							Si por cualquier motivo la operación no llega a concretarse, el
							lote de divisas o efectivo será inmediatamente devuelto al
							operador que efectuó el depósito.
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default FaqPage;

import { Box } from '@mui/material';

export const ButtonsContainer = ({ children }: any) => {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				columnGap: '12.5px',
			}}
		>
			{children}
		</Box>
	);
};

import { Button, ButtonProps, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface CommonButtonProps {
	children: string | React.ReactNode;
	variant?: 'text' | 'outlined' | 'contained';
	color?: ButtonProps['color'];
	action?: () => void;
	disabled?: boolean;
	EndIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
		muiName: string;
	};
	minWidth?: string;
	maxHeight?: string;
	sxBgcolor?: string;
	sxColor?: string;
	margin?: string;
}

export const CommonButton = ({
	children,
	variant = 'contained',
	color = 'primary',
	action = () => {},
	disabled = false,
	EndIcon,
	minWidth = '100px',
	maxHeight,
	sxBgcolor,
	sxColor,
	margin,
}: CommonButtonProps) => {
	return (
		<Button
			onClick={action}
			variant={variant}
			color={color}
			sx={{
				'minWidth': minWidth,
				'maxHeight': maxHeight,
				'padding': '6px 12px',
				'display': 'flex',
				'flexDirection': 'row',
				'bgcolor': sxBgcolor,
				'color': sxColor,
				'margin': margin,
				'&:hover': {
					backgroundColor: sxBgcolor,
					opacity: 0.8,
				},
			}}
			disabled={disabled}
		>
			<Typography fontSize={12} color={'white'} fontWeight={600}>
				{children}
			</Typography>
			{EndIcon && <EndIcon style={{ color: 'white' }} />}
		</Button>
	);
};

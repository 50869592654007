import { useContext, useEffect } from 'react';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';
import { TableCell, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import {
	currenciesFormater,
	decimalFormater,
} from '../../../utils/currencies/currencies-formater';

interface ITableBodyExpired {
	children: any;
}

export const TableBodyExpired = ({ children }: ITableBodyExpired) => {
	const { statuses } = useContext(CambiappContext) as CambiappContextType;

	useEffect(() => {
		if (!statuses.isLoading && statuses.data?.length === 0) statuses.refetch();
	}, [statuses]);

	return (
		<TableRow>
			<TableCell>
				<Typography>{children.listingBatches[0]?.amount}</Typography>
				<Typography>{children.listingBatches[1]?.amount}</Typography>
			</TableCell>
			<TableCell>
				<Typography>
					{children.listingBatches[0]?.billDenomination?.split('-')[0]}
				</Typography>
				<Typography>
					{children.listingBatches[1]?.billDenomination?.split('-')[0]}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography
					sx={{
						color: children.listingBatches[0]?.billColor,
						textAlign: 'center',
					}}
				>
					{children.listingBatches[0]?.billVersion.split('/')[0]}
				</Typography>
				<Typography
					sx={{
						color: children.listingBatches[1]?.billColor,
						textAlign: 'center',
					}}
				>
					{children.listingBatches[1]?.billVersion.split('/')[0]}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography sx={{ textAlign: 'center' }}>
					{statuses.data &&
					statuses.data.indexOf(children.listingBatches[0]?.billStatus) >= 0
						? statuses.data.indexOf(children.listingBatches[0]?.billStatus) + 1
						: null}
				</Typography>
				<Typography sx={{ textAlign: 'center' }}>
					{statuses.data &&
					statuses.data.indexOf(children.listingBatches[1]?.billStatus) >= 0
						? statuses.data.indexOf(children.listingBatches[1]?.billStatus) + 1
						: null}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography>{currenciesFormater(children.totalAmount)}</Typography>
			</TableCell>
			<TableCell>{currenciesFormater(children.unitPrice, 'ARS')[1]}</TableCell>
			<TableCell>{decimalFormater(children.total)}</TableCell>
			<TableCell>
				{moment(children.creationAt).format('DD/MM/YYYY HH:mm')}
			</TableCell>
			<TableCell>YO</TableCell>
			<TableCell>{children.type}</TableCell>
			<TableCell>
				{moment(children.expiredDate).format('DD/MM/YYYY HH:mm')}
			</TableCell>
			<TableCell>{children.status}</TableCell>
		</TableRow>
	);
};

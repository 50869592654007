import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	styled,
	TableContainer,
	Paper,
} from '@mui/material';
import { expiredPublicationsTableHeader } from '../table-config';
import { TableBodyExpired } from './expired-table-body.component';

interface IExpiredTableComponent {
	expiredPublications: any[];
}

const StyledTable = styled(Table)({
	thead: {
		tr: {
			th: {
				color: 'white',
				padding: '4px',
				textAlign: 'center',
				border: '1px solid black',
			},
		},
		backgroundColor: '#666666',
	},
	tbody: {
		backgroundColor: '#191919',
		tr: {
			td: {
				padding: '4px',
				textAlign: 'center',
				border: '1px solid black',
			},
		},
	},
});

export const ExpiredTableComponent = ({
	expiredPublications,
}: IExpiredTableComponent) => {
	return (
		<TableContainer
			component={Paper}
			sx={{ marign: '8px', whiteSpace: 'nowrap' }}
		>
			<StyledTable>
				<TableHead>
					{expiredPublicationsTableHeader.map((headerRow: any, index: any) => {
						return (
							<TableRow key={`header-${index}`}>
								{headerRow.map((tab: any, index: any) => {
									return (
										<TableCell
											key={`cell-${index}`}
											colSpan={tab.colSpan}
											rowSpan={tab.rowSpan}
											sx={{ backgroundColor: tab.backgroundColor }}
											align="center"
										>
											{tab.title}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableHead>
				<TableBody>
					{expiredPublications.map((publication) => {
						return (
							<TableBodyExpired key={publication.id}>
								{publication}
							</TableBodyExpired>
						);
					})}
				</TableBody>
			</StyledTable>
		</TableContainer>
	);
};

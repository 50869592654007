import { Check } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	CambiappContext,
	CambiappContextType,
} from '../../../context/cambiapp.context';
import { Currency } from '../../../interfaces/publication.interface';

const StyledApproved = styled(Box)(({ theme }) => ({
	'marginTop': '40%',
	'display': 'flex',
	'flexDirection': 'column',
	'alignItems': 'center',
	'justifyContent': 'center',
	'rowGap': '50px',
	'.check': {
		fontSize: '96px',
		color: theme.palette.action.active,
		backgroundColor: theme.palette.success.main,
		borderRadius: '50%',
	},
}));

export const ApprovedPublication = () => {
	const navigate = useNavigate();
	const { setNewBuyOrder, setOrderAmr } = useContext(
		CambiappContext,
	) as CambiappContextType;

	useEffect(() => {
		setNewBuyOrder({
			type: 'COMPRA',
			amr: '00:30',
			availableAppointments: [],
			isPartialAllowed: false,
			minimunPartialAmount: 0,
			currency: Currency.USD,
		});
		setOrderAmr('00:30');
		setTimeout(() => {
			navigate('/buy');
		}, 5000);
	}, [setNewBuyOrder, setOrderAmr, navigate]);

	return (
		<StyledApproved>
			<Typography variant="h6" fontWeight={800} textAlign={'center'}>
				TU PUBLICACIÓN DE COMPRA FUE CREADA EXITOSAMENTE
			</Typography>
			<Check className="check" />
		</StyledApproved>
	);
};

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { IAvailableAppointments } from '../../context/cambiapp.context';
import {
	ListingShifts,
	Publication,
} from '../../interfaces/publication.interface';
import { convertTime } from '../date';

const isToday = (
	date: string,
	shift: string,
	advanceMinimumRequired: string,
) => {
	if (moment(date.split('T')[0]).isSame(moment(), 'day')) {
		if (
			moment(shift, 'HH:mm')
				.subtract(advanceMinimumRequired.split(':')[0], 'hours')
				.subtract(parseInt(advanceMinimumRequired.split(':')[1]) + 1, 'minutes')
				.isAfter(moment().add(3, 'hours'))
		) {
			return true;
		} else {
			return false;
		}
	} else {
		return true;
	}
};

const groupShift = (publication: Publication) => {
	let toDay = '';
	let array: any[] = [];
	publication.listingShifts.length > 0 &&
		publication.listingShifts.sort((a: any, b: any) =>
			a.date.split('T')[0].localeCompare(b.date.split('T')[0]),
		) &&
		publication.listingShifts.map(
			(
				{ date, shift, isAvailable }: IAvailableAppointments,
				index1: number,
			) => {
				if (
					toDay !== date.split('T')[0] &&
					moment(date.split('T')[0])
						.add(shift.split(':')[0], 'hours')
						.add(shift.split(':')[1], 'minutes')
						.subtract(3, 'hours')
						.subtract(publication.advanceMinimumRequired.split(':')[0], 'hours')
						.subtract(
							parseInt(publication.advanceMinimumRequired.split(':')[1]) + 1,
							'minutes',
						)
						.isAfter(moment()) &&
					isAvailable
				) {
					toDay = date.split('T')[0];
					let aux = moment(date)
						.add(3, 'hours')
						.format('D/MM/YYYY')
						.toUpperCase();

					let valor: string[] = [];
					valor = [...valor, aux];

					publication.listingShifts.map(
						(publicationDateDetails: any, index: number) => {
							if (
								date.split('T')[0] ===
									publicationDateDetails.date.split('T')[0] &&
								isToday(
									date,
									publicationDateDetails.shift,
									publication.advanceMinimumRequired,
								) &&
								publicationDateDetails.isAvailable
							) {
								valor = [
									...valor,
									convertTime(publicationDateDetails.shift, 'ARG'),
								];
							}
						},
					);
					array = [...array, valor];
				}
			},
		);
	return array;
};

export const convertHours = (hours: string) => {
	const [hour, minutes] = hours.split(':');
	return parseFloat(`${hour}.${minutes}`);
};

export const showShifts = (publication: Publication) => {
	const shifts = groupShift(publication);

	return shifts.map((shift, index2: number) => {
		const orderData = [
			shift[0],
			...shift.slice(1).sort((a: string, b: string) => {
				return convertHours(a) - convertHours(b);
			}),
		];

		return (
			<div style={{ paddingTop: '8px' }}>
				{orderData.map((dayOfArray: string, index: number) => {
					if (index === 0) {
						return <span style={{ fontWeight: 900 }}>{dayOfArray}: </span>;
					} else {
						if (shift.length - 1 !== index) {
							return `${dayOfArray}, `;
						} else {
							return `${dayOfArray}. `;
						}
					}
				})}
			</div>
		);
	});
};

export const orderShifts = (listingShifts: ListingShifts[]) => {
	listingShifts.sort((a: ListingShifts, b: ListingShifts) => {
		const hourA = a.shift.split(':').map(Number);
		const hourB = b.shift.split(':').map(Number);

		if (hourA[0] !== hourB[0]) {
			return hourA[0] - hourB[0];
		} else if (hourA[1] !== hourB[1]) {
			return hourA[1] - hourB[1];
		} else {
			const dateA = new Date(a.date).getTime();
			const dateB = new Date(b.date).getTime();

			return dateA - dateB;
		}
	});
};

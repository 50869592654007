import axios from 'axios';
import { ListingType } from '../../interfaces/publication.interface';
import { NavigateFunction } from 'react-router-dom';
import { IAvailableAppointments } from '../../context/cambiapp.context';
import { Currency } from '../../interfaces/publication.interface';

const BACKEND = process.env.REACT_APP_BACKEND;

let isAuthorized = true;

const relocateWithoutAlert = () => {
	window.location = '/login' as unknown as Location;
};

const relocateToLogin = () => {
	isAuthorized = false;
	if (!isAuthorized) {
		localStorage.clear();
		alert('Sesion expirada');
		window.location = '/login' as unknown as Location;
	}
};

interface NewOrderDto {
	type: ListingType;
	unitPrice: string;
	advanceMinimumRequired: string;
	isArchived: false;
	isDiscarded: false;
	minimumDenominationToReceive: string;
	listingBatches: BatchesDto[];
	listingShifts: IAvailableAppointments[];
	expirationDate: Date;
	isPartialAllowed: boolean;
	minimunPartialAmount: number;
	currency: Currency;
}

export interface BatchesDto {
	amount: number;
	billVersion: string;
	billDenomination: string;
	billColor: string;
	billStatus: string;
}

class AsyncHandler {
	async loginHandler(
		user: string,
		password: string,
		navigate: NavigateFunction,
	) {
		const headers = {
			'Content-Type': 'application/json',
		};
		axios
			.post(`${BACKEND}/auth/login`, JSON.stringify({ user, password }), {
				headers,
			})
			.then(
				(response) => {
					localStorage.setItem('firebaseToken', response.data.accessToken);
					isAuthorized = true;
					navigate('/');
				},
				() => {
					alert('Datos invalidos');
				},
			);
	}

	async syncNotificationToken(notificationToken: string) {
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': localStorage.getItem('firebaseToken'),
		};
		axios
			.patch(
				`${BACKEND}/user/notification-token`,
				JSON.stringify({ notificationToken }),
				{ headers },
			)
			.catch(({ response }) => {
				if (!isAuthorized) relocateWithoutAlert();
				if (response.status === 401 && isAuthorized) relocateToLogin();
			});
	}

	async retrieveSellPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/listings/own/VENTA/${currency}`, { headers })
				.then(
					({ data }) => data.data,
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveConfirmedSellPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/listings/confirm/VENTA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveCompletedSellPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/listings/complete/VENTA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveExpiredSellPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			const expiredPublications = await axios
				.get(`${BACKEND}/listings/expired/VENTA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			const caducedPublications = await axios
				.get(`${BACKEND}/listings/caduced/VENTA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			return [...expiredPublications, ...caducedPublications];
		}
		return [];
	}

	async retrieveAbortedSellPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			const abortedPublications = await axios
				.get(`${BACKEND}/listings/aborted/VENTA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			const deletedPublications = await axios
				.get(`${BACKEND}/listings/deleted/VENTA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			return [...abortedPublications, ...deletedPublications];
		}
		return [];
	}

	async retrieveBuyPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/listings/own/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => data.data,
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveConfirmedBuyPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/listings/confirm/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveCompletedBuyPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/listings/complete/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveExpiredBuyPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			const expiredPublications = await axios
				.get(`${BACKEND}/listings/expired/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			const caducedPublications = await axios
				.get(`${BACKEND}/listings/caduced/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			return [...expiredPublications, ...caducedPublications];
		}
		return [];
	}

	async retrieveAbortedBuyPublications(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			const abortedPublications = await axios
				.get(`${BACKEND}/listings/aborted/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			const deletedPublications = await axios
				.get(`${BACKEND}/listings/deleted/COMPRA/${currency}`, { headers })
				.then(
					({ data }) => {
						return data;
					},
					({ response }) => {
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
			return [...abortedPublications, ...deletedPublications];
		}
		return [];
	}

	async retrieveStatuses() {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios.get(`${BACKEND}/currencies/statuses`, { headers }).then(
				({ data }) => data.data,
				({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
					return [];
				},
			);
		}
		return [];
	}

	async retrieveDenominations(currency: Currency) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/denominations/${currency}`, { headers })
				.then(
					({ data }) => data.data,
					({ response }) => {
						if (!isAuthorized) relocateWithoutAlert();
						if (response.status === 401 && isAuthorized) relocateToLogin();
						return [];
					},
				);
		}
		return [];
	}

	async retrieveArsDenominations() {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios.get(`${BACKEND}/denominations/ARS`, { headers }).then(
				({ data }) => data.data,
				({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				},
			);
		}
		return [];
	}

	async publishNewOrder(
		newOrder: NewOrderDto,
		setPublished: (isPubliched: boolean) => void,
		previousStep: () => void,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			await axios
				.post(`${BACKEND}/listings`, JSON.stringify(newOrder), {
					headers,
				})
				.then(() => setPublished(true))
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
					if (response.status === 406) {
						alert(
							'La fecha de vencimiento ha expirado, por favor elija las citas posibles nuevamente.',
						);
						previousStep();
					}
				});
		}
	}

	async fetchPublicatioById(id: number) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.get(`${BACKEND}/listings/${id}`, { headers })
				.then((response) => response.data)
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async deletePublicatioById(id: number, closeModal: () => void) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.delete(`${BACKEND}/listings/${id}`, { headers })
				.then(() => {
					alert('Publicacion eliminada con exito');
					closeModal();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async requestChanges(id: number, closeDialog: () => void) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.patch(`${BACKEND}/listings/${id}/request-changes`, {}, { headers })
				.then(() => {
					alert('Se ha enviado la peticion al administrador');
					closeDialog();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async requestAbort(id: number, closeDialog: () => void) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.patch(`${BACKEND}/listings/${id}/aborted`, {}, { headers })
				.then(() => {
					alert('La operación se ha abortado exitosamente');
					closeDialog();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async fetchAppointments(date: any) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios
				.get(`${BACKEND}/calendar?dateFrom=${date}`, { headers })
				.then((response) => response.data.data)
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async confirmAppointment(
		id: number,
		listingAmount: number,
		appointmentDate: string,
		reservationHour: string,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios.patch(
				`${BACKEND}/listings/${id}/confirm`,
				{
					listingAmount,
					appointmentDate,
					reservationHour,
				},
				{ headers },
			);
		}
	}

	async checkAdminStatus() {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return (await axios.get(`${BACKEND}/user/check-admin`, { headers })).data;
		}
	}

	async changePublicationPauseStatus(
		id: number,
		isPaused: boolean,
		closeModal: () => void,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.patch(
					`${BACKEND}/listings/${id}/pause`,
					{ isPaused: !isPaused },
					{ headers },
				)
				.then(() => {
					isPaused
						? alert('Publicacion activada con exito')
						: alert('Publicacion pausada con exito');
					closeModal();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async changePublicationUnitPrice(
		id: number,
		unitPrice: number,
		closeModal: () => void,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.patch(
					`${BACKEND}/listings/${id}/unit-price`,
					{ unitPrice },
					{ headers },
				)
				.then(() => {
					alert('Precio unitario actualizado con exito');
					closeModal();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async changePublicationListingBatches(
		id: number,
		listingBaches: BatchesDto[],
		closeModal: () => void,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.patch(`${BACKEND}/listings/${id}/listing-batches`, listingBaches, {
					headers,
				})
				.then(() => {
					alert('Lotes actualizados con exito');
					closeModal();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async hidePublications(
		appointmentsToHide: number[],
		closeDialog: () => void,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios
				.patch(
					`${BACKEND}/listings/hide-listing`,
					{ appointmentsToHide },
					{ headers },
				)
				.then(() => {
					alert('Publicaciones eliminadas con exito');
					closeDialog();
				})
				.catch(({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
				});
		}
	}

	async handlePartialConfirmation(
		id: number,
		amountToConfirm: number,
		appointmentDate: string,
		reservationHour: string,
	) {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return await axios.patch(
				`${BACKEND}/listings/${id}/confirm/partial`,
				JSON.stringify({
					amountToConfirm,
					appointmentDate,
					reservationHour,
				}),
				{ headers },
			);
		}
	}

	async retrieveCurrencies() {
		if (localStorage.getItem('firebaseToken')) {
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': localStorage.getItem('firebaseToken'),
			};
			return axios.get(`${BACKEND}/currencies/list`, { headers }).then(
				({ data }) => {
					return data.data;
				},
				({ response }) => {
					if (!isAuthorized) relocateWithoutAlert();
					if (response.status === 401 && isAuthorized) relocateToLogin();
					console.error('Error response from currencies endpoint:', response);
					return [];
				},
			);
		}
		console.warn('No firebase token found in localStorage');
		return [];
	}

	async updateListingShifts(
		listingId: number,
		newShifts: IAvailableAppointments[],
	) {
		const firebaseToken = localStorage.getItem('firebaseToken');

		if (!firebaseToken) {
			throw new Error('Firebase token not found in localStorage');
		}

		const headers = {
			'Content-Type': 'application/json',
			'Authorization': firebaseToken,
		};

		try {
			const response = await axios.patch(
				`${BACKEND}/listings/${listingId}/listing-shifts`,
				newShifts,
				{
					headers,
				},
			);

			return response.data;
		} catch (error: any) {
			if (error.response) {
				console.error('API response error:', error.response.data);
				console.error('Status code:', error.response.status);

				if (error.response.status === 401) {
					console.error('Authentication error');
				} else if (error.response.status === 403) {
					console.error('Authorization error');
				} else {
					console.error('API error:', error.response.data);
				}
			} else if (error.request) {
				console.error('No response received from API:', error.request);
			} else {
				console.error('Error setting up the request:', error.message);
			}
			throw error;
		}
	}
}

export const asyncHandler = new AsyncHandler();

import { Tooltip } from '@mui/material';

interface ShiftsTooltipProps {
	title: string;
	children: any;
}

export const ShiftsTooltip = ({ title, children }: ShiftsTooltipProps) => {
	return (
		<Tooltip
			disableFocusListener
			enterTouchDelay={0}
			leaveTouchDelay={15000}
			placement="top"
			slotProps={{
				tooltip: {
					style: {
						backgroundColor: '#474744',
						color: 'white',
						marginBottom: '3px',
						padding: '2px 10px 10px 10px',
						borderRadius: '6px',
					},
				},
			}}
			title={title}
		>
			<button
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'relative',
					width: '30px',
					height: '30px',
					backgroundColor: 'black',
					borderRadius: '15px 15px',
					border: 'none',
					fontSize: ' 1rem',
				}}
			>
				{children}
			</button>
		</Tooltip>
	);
};

import { Box, Modal, Typography } from '@mui/material';
import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
	CambiappContext,
	CambiappContextType,
	IAvailableAppointments,
} from '../context/cambiapp.context';
import { ListingShifts } from '../interfaces/publication.interface';
import { asyncHandler } from '../utils/async-handler';
import { convertAppointmentTime } from '../utils/date';
import { CommonButton } from './button/common-button';
import { InformationTooltip } from './tooltip/information-tooltip';

interface IPublication {
	setOpen: (event: boolean) => void;
	isOpen: boolean;
	children: { [key: string]: any };
	listingShifts: [ListingShifts];
	setRefresh: () => void;
}

export const EditShiftPublication = ({
	isOpen,
	setOpen,
	children,
	listingShifts,
	setRefresh,
}: IPublication) => {
	const [isModalOpen, setModalOpen] = useState(isOpen);

	const { setSelectedAvailableAppointments, orderAmr, setOrderAmr } =
		useContext(CambiappContext) as CambiappContextType;
	const [appointments, setAppointments] = useState<any[]>([]);
	const hourValue = orderAmr.split(':')[0];
	const minutesValue = orderAmr.split(':')[1];
	const [time, setTime] = useState(
		moment()
			.utc(true)
			.add(hourValue, 'hours')
			.add(minutesValue, 'minutes')
			.format(),
	);

	const convertShift = (shift: string) => {
		let num = parseFloat(shift) - 3;

		if (num < 10) {
			return num.toString().padStart(2, '0');
		} else return num.toString();
	};

	const selectedShifts = listingShifts.map((obj) => ({
		date: obj.date,
		shift: convertShift(obj.shift) + ':' + obj.shift.split(':')[1],
	}));
	const [selectedDates, setSelectedDates] =
		useState<IAvailableAppointments[]>(selectedShifts);

	const addOrRemoveDate = (dateToEvaluate: IAvailableAppointments) => {
		if (
			selectedDates.some((date) => {
				if (
					date.date === dateToEvaluate.date &&
					date.shift === dateToEvaluate.shift
				)
					return true;
				return false;
			})
		) {
			const newSelectedDates = selectedDates.filter((date) => {
				if (date.date === dateToEvaluate.date)
					return (
						date.shift !== dateToEvaluate.shift &&
						date.date === dateToEvaluate.date
					);
				return true;
			});
			setSelectedDates(newSelectedDates);
		} else {
			setSelectedDates([...selectedDates, dateToEvaluate]);
		}
	};

	useEffect(() => {
		selectedShifts.forEach((date) => addOrRemoveDate(date));
		setSelectedDates([]);

		const retrieveAppointments = async () => {
			let dates;

			dates = await asyncHandler.fetchAppointments(time);
			setAppointments(dates);
		};
		retrieveAppointments();
	}, [hourValue, minutesValue]);

	const selectAllAppointments = () => {
		const availableAppointments: IAvailableAppointments[] = [];
		appointments?.map((appointment) => {
			return appointment.hours.forEach((hour: any) => {
				const date = {
					date: appointment.date,
					shift: hour.shift,
				};
				availableAppointments.push(date);
			});
		});
		if (selectedDates.length === availableAppointments.length) {
			setSelectedDates([]);
		} else {
			setSelectedDates(availableAppointments);
		}
	};

	useEffect(() => {
		setSelectedDates(
			selectedDates
				.sort((a, b) => {
					if (a.shift.split(':')[0] === b.shift.split(':')[0]) {
						if (a.shift.split(':')[1] > b.shift.split(':')[1]) return 1;
						if (a.shift.split(':')[1] < b.shift.split(':')[1]) return -1;
					}
					if (a.shift.split(':')[0] > b.shift.split(':')[0]) return 1;
					if (a.shift.split(':')[0] < b.shift.split(':')[0]) return -1;
					return 0;
				})
				.sort((a, b) => {
					if (a.date.split('T')[0] > b.date.split('T')[0]) return 1;
					if (a.date.split('T')[0] < b.date.split('T')[0]) return -1;
					return 0;
				}),
		);
	}, [selectedDates, selectedDates.length]);

	const handleConfirm = async () => {
		if (selectedDates.length > 0) {
			setOrderAmr(`${hourValue}:${minutesValue}`);
			setSelectedAvailableAppointments(selectedDates);
			setOpen(false);
			setModalOpen(false);

			try {
				const response = await asyncHandler.updateListingShifts(
					children.id,
					await convertAppointmentTime(selectedDates),
				);

				alert('Los turnos se han actualizado correctamente.');
			} catch (error) {
				alert(
					'Ha ocurrido un error al actualizar los turnos. Por favor, inténtalo de nuevo.',
				);
				console.error('Error updating shifts:', error);
			}
		}
		setRefresh();
	};

	return (
		<Modal
			open={isModalOpen}
			sx={{
				margin: '0 auto',
				backdropFilter: 'blur(5px)',
				backgroundColor: '#221F20',
				height: '100%',
				padding: '50px 0',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: '100%',
				}}
			>
				<Box
					sx={{
						'display': 'flex',
						'flexDirection': 'column',
						'alignItems': 'center',
						'rowGap': '25px',
						'@media (max-width: 500px)': {
							rowGap: '5px',
						},
						'maxWidth': '768px',
						'padding': '25px',
					}}
				>
					<Typography
						sx={{
							textAlign: 'center',
							fontWeight: 600,
							fontSize: 18,
							marginBottom: '50px',
						}}
					>
						Citas posibles
					</Typography>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '25px',
						}}
					>
						<Typography
							sx={{
								fontWeight: 800,
								margin: '0 20px',
								textAlign: 'justify',
							}}
						>
							Selecciona qué días y horarios alternativos proponés para la cita
							de transacción en la Casa
						</Typography>
						<InformationTooltip
							title={`La cita es la fecha y hora en la que se realizará la transacción en la Casa. Seleccioná una, varias, o todas las citas alternativas que se encuentren disponibles. El comprador que acepte tu oferta elegirá una de esas alternativas, y la misma quedará fijada como cita de transacción, no pudiendo modificarse.`}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '16px',
						}}
					>
						<CommonButton
							action={() => {
								selectAllAppointments();
							}}
							sxBgcolor={'#666666'}
						>
							<Typography fontSize={12} color={'white'} fontWeight={600}>
								Seleccionar todas
							</Typography>
						</CommonButton>
					</Box>
					{appointments &&
						appointments.length > 0 &&
						appointments.map((appointment) => {
							return (
								<Box
									key={appointment.date}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										marginBottom: '25px',
									}}
								>
									<Typography>
										{moment(appointment.date)
											.add(3, 'hours')
											.format('dddd D [de] MMMM')
											.toUpperCase()}
									</Typography>
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											justifyContent: 'center',
										}}
									>
										{appointment.hours.map((hour: any) => {
											const dateObject = {
												date: appointment.date,
												shift: hour.shift,
											};
											if (hour.available)
												return (
													<Typography
														sx={{
															display: 'inline-block',
															bgcolor: selectedDates.some(
																(date) =>
																	date.date === dateObject.date &&
																	date.shift === dateObject.shift,
															)
																? '#3C8846'
																: '#405F8E',
															margin: '2px',
															padding: '1px',
															cursor: 'pointer',
														}}
														key={`${appointment.date}${hour.shift}`}
														onClick={() => {
															addOrRemoveDate(dateObject);
														}}
													>
														{hour.shift}
													</Typography>
												);
											return (
												<Fragment
													key={`${appointment.date}${hour.shift}`}
												></Fragment>
											);
										})}
									</Box>
								</Box>
							);
						})}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						maxWidth: '768px',
						padding: '25px',
					}}
				>
					<CommonButton
						action={() => {
							setOpen(false);
							setModalOpen(false);
						}}
						sxBgcolor={'#666666'}
					>
						<Typography fontSize={12} color={'white'} fontWeight={600}>
							ATRÁS
						</Typography>
					</CommonButton>
					<CommonButton action={handleConfirm} sxBgcolor={'#05A550'}>
						<Typography fontSize={12} color={'white'} fontWeight={600}>
							GUARDAR CAMBIOS
						</Typography>
					</CommonButton>
				</Box>
			</Box>
		</Modal>
	);
};

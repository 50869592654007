import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	InputAdornment,
	Modal,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import {
	ListingBatches,
	ListingShifts,
} from '../interfaces/publication.interface';
import { asyncHandler } from '../utils/async-handler';
import { CommonButton } from './button/common-button';
import { ChangeQttyComponent } from './change-qtty.component';
import { EditShiftPublication } from './edit-shift-publication.component';

interface IPublication {
	setOpen: (event: boolean) => void;
	isOpen: boolean;
	children: { [key: string]: any };
	listingShifts: [ListingShifts];
	listingBatches: [ListingBatches];
	setRefresh: () => void;
	onChangePriceChanged: (changed: boolean) => void;
	onChangeTotalAmountChanged: (changed: boolean) => void;
}

export const EditPublication = ({
	isOpen,
	setOpen,
	children,
	listingShifts,
	listingBatches,
	setRefresh,
	onChangePriceChanged,
	onChangeTotalAmountChanged,
}: IPublication) => {
	const [isEditDialogOpen, setEditDialogOpen] = useState(false);
	const [arsPrice, setArsPrice] = useState<number>();
	const [isModalOpen, setModalOpen] = useState(isOpen);
	const [isChangeQttyModalOpen, setChangeQttyModalOpen] = useState(false);
	const [isModalShiftOpen, setModalShiftOpen] = useState(false);
	const { unitPrice } = children;

	return (
		<>
			<Modal
				open={isModalOpen}
				sx={{
					margin: '0 auto',
					backdropFilter: 'blur(5px)',
					backgroundColor: '#221F20',
					height: '100%',
					padding: '50px 0',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Box
						sx={{
							'display': 'flex',
							'flex': 20,
							'flexDirection': 'column',
							'alignItems': 'flex-start',
							'justifyContent': 'center',
							'rowGap': '25px',
							'@media (max-width: 500px)': {
								rowGap: '5px',
							},
						}}
					>
						<Button
							variant="contained"
							sx={{
								minWidth: '300px',
								maxWidth: '400px',
								width: '50%',
								padding: '10px 0',
								color: 'white',
								backgroundColor: '#666666',
							}}
							color="warning"
							onClick={() => setEditDialogOpen(true)}
						>
							CAMBIAR PRECIO
						</Button>
						<Dialog
							open={isEditDialogOpen}
							onClose={() => setEditDialogOpen(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								{`Indicá el nuevo precio unitario en pesos ARS al que querés ${
									children.action === 'compro' ? 'comprar' : 'vender'
								}`}
							</DialogTitle>
							<Box
								sx={{
									padding: '1px 25px 25px',
								}}
							>
								<TextField
									placeholder={unitPrice}
									value={arsPrice || ''}
									variant="outlined"
									onChange={(e) => setArsPrice(parseInt(e.target.value))}
									sx={{
										display: 'flex',
										backgroundColor: '#000000',
										border: 'none',
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">ARS</InputAdornment>
										),
									}}
								/>
							</Box>
							<DialogActions
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									padding: '0 25px 25px',
								}}
							>
								<Button
									color="error"
									variant="contained"
									sx={{ color: 'white' }}
									onClick={() => {
										setEditDialogOpen(false);
										setArsPrice(undefined);
									}}
								>
									Cancelar
								</Button>
								<Button
									color="warning"
									variant="contained"
									sx={{ color: 'white', backgroundColor: '#191919' }}
									onClick={() => {
										if (arsPrice) {
											setEditDialogOpen(false);
											asyncHandler.changePublicationUnitPrice(
												children.id,
												arsPrice,
												() => {
													setOpen(false);
													setModalOpen(false);
												},
											);
											setArsPrice(undefined);
											onChangePriceChanged(true);
										}
									}}
									autoFocus
								>
									Actualizar precio
								</Button>
							</DialogActions>
						</Dialog>
						<Button
							variant="contained"
							sx={{
								minWidth: '300px',
								maxWidth: '400px',
								width: '50%',
								padding: '10px 0',
								color: 'white',
								backgroundColor: '#666666',
							}}
							color="warning"
							onClick={() => {
								setChangeQttyModalOpen(true);
							}}
						>
							CAMBIAR CANTIDAD
						</Button>
						<Button
							variant="contained"
							sx={{
								minWidth: '300px',
								maxWidth: '400px',
								width: '50%',
								padding: '10px 0',
								color: 'white',
								backgroundColor: '#666666',
							}}
							color="warning"
							onClick={() => setModalShiftOpen(true)}
						>
							CAMBIAR CITAS
						</Button>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row',
							justifyContent: 'flex-start',
							width: '100%',
							maxWidth: '768px',
							padding: '25px',
						}}
					>
						<CommonButton
							action={() => {
								setOpen(false);
								setModalOpen(false);
							}}
							sxBgcolor={'#666666'}
							maxHeight="40px"
						>
							<Typography fontSize={12} color={'white'} fontWeight={600}>
								ATRÁS
							</Typography>
						</CommonButton>
					</Box>
				</Box>
			</Modal>

			<Modal
				open={isChangeQttyModalOpen}
				onClose={() => {
					setChangeQttyModalOpen(false);
					setRefresh();
				}}
				aria-labelledby="change-qtty-modal"
				aria-describedby="change-qtty-modal-description"
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<ChangeQttyComponent
						data={{
							listingBatches: listingBatches,
							totalAmount: children.totalAmount,
							isPartialAllowed: children.isPartialAllowed,
							minimunPartialAmount: children.minimunPartialAmount,
							unitPrice: children.unitPrice,
							minimumDenominationToReceive:
								children.minimumDenominationToReceive,
						}}
						onClose={() => {
							setChangeQttyModalOpen(false);
							setRefresh();
						}}
						onChangeTotalAmountChanged={onChangeTotalAmountChanged}
					>
						{children}
					</ChangeQttyComponent>
				</Box>
			</Modal>
			{isModalShiftOpen && (
				<EditShiftPublication
					isOpen={isModalShiftOpen}
					setOpen={setModalShiftOpen}
					listingShifts={listingShifts}
					setRefresh={() => setRefresh()}
				>
					{children}
				</EditShiftPublication>
			)}
		</>
	);
};

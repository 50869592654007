import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import Login from '../../pages/login/login.page';
import Home from '../../pages/home/home.page';
import SellingDashboard from '../../pages/sell/sell.page';
import MainLayout from '../../layouts/main-layout';
import BuyingDashboard from '../../pages/buy/buy.page';
import NewSellPublication from '../../pages/new-publication/new-sell.page';
import NewBuyPublication from '../../pages/new-publication/new-buy.page';
import SellDetailPage from '../../pages/sell/sell-detail.page';
import BuyDetailPage from '../../pages/buy/buy-detail.page';
import FaqPage from '../../pages/faq/faq.page';
import PartialSellDetailPage from '../../pages/sell/partial-sell-detail.page';
import PartialBuyDetailPage from '../../pages/buy/partial-buy-detail.page';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/">
			<Route path="login" element={<Login />} />
			<Route path="faq" element={<FaqPage />} />
			<Route element={<MainLayout />}>
				<Route path="">
					<Route index element={<Home />} />
				</Route>
				<Route path="sell">
					<Route index element={<SellingDashboard />} />
					<Route path="new" element={<NewSellPublication />} />
					<Route path=":id" element={<SellDetailPage />} />
					<Route path=":id/partial" element={<PartialSellDetailPage />} />
				</Route>
				<Route path="buy">
					<Route index element={<BuyingDashboard />} />
					<Route path="new" element={<NewBuyPublication />} />
					<Route path=":id" element={<BuyDetailPage />} />
					<Route path=":id/partial" element={<PartialBuyDetailPage />} />
				</Route>
			</Route>
		</Route>,
	),
);

export default router;

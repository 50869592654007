import { RouterProvider } from 'react-router-dom';
import router from './utils/router';
import moment from 'moment';
import './App.css';
import 'moment/locale/es';

function App() {
	moment.locale('es');
	return <RouterProvider router={router} />;
}

export default App;

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeOptions } from '@mui/material/styles';

const sharedOptions: ThemeOptions = {
  typography: {
    allVariants: {
      fontFamily: 'roboto',
    }
  }
};

export const darkTheme = createTheme({
  ...sharedOptions,
  palette: {
    mode: 'dark',
    background: {
      default: '#221f20',
    },
  },
});

export const theme = createTheme({
  ...sharedOptions,
  palette: {
    mode: 'light',
    background: {
      default: '#F1F1F2', 
    },
  },
});

export { theme as default };



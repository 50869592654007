export const completedPublicationsTableHeader = [
	[
		{
			title: 'Cant. Lote',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Denóm',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Versión',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Est',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Total',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Precio Unitario',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Precio Total',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Publicación',
			colSpan: 3,
			rowSpan: 1,
		},
		{
			title: 'Confirmación',
			colSpan: 3,
			rowSpan: 1,
		},
		{
			title: 'Cód. de Op.',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Cita de Trans.',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Venta Concretada',
			colSpan: 1,
			rowSpan: 2,
		},
	],
	[
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Emisor',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Operación',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Operador',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Decisión',
			colSpan: 1,
			rowSpan: 1,
		},
	],
];

export const expiredPublicationsTableHeader = [
	[
		{
			title: 'Cant. lote',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Denom.',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Versión',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Est',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Total',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Precio unitario',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Precio total',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Publicación',
			colSpan: 3,
			rowSpan: 1,
		},
		{
			title: 'Vencimiento/Caducidad',
			colSpan: 2,
			rowSpan: 1,
		},
	],
	[
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Emisor',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Operacion',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Estado',
			colSpan: 1,
			rowSpan: 1,
		},
	],
];

export const abortedPublicationsTableHeader = [
	[
		{
			title: 'Cant. lote',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Denom.',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Versión',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Est',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Total',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Precio unitario',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Precio total',
			colSpan: 1,
			rowSpan: 2,
		},
		{
			title: 'Publicación',
			colSpan: 3,
			rowSpan: 1,
		},
		{
			title: 'Confirmación',
			colSpan: 5,
			rowSpan: 1,
		},
		{
			title: 'Aborto/Eliminación',
			colSpan: 3,
			rowSpan: 1,
		},
	],
	[
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Emisor',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Operación',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Operador',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Decisión',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Cód. de Op.',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Cita de Trans',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Fecha/Hora',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Operador',
			colSpan: 1,
			rowSpan: 1,
		},
		{
			title: 'Decisión',
			colSpan: 1,
			rowSpan: 1,
		},
	],
];
